import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Modal from "react-responsive-modal";
import "./details-price.css";
import { connect } from "react-redux";
import {
  addToWishlistUnsafe,
  addToPendingOrdersRedux,
  getAllD2DRatesRedux,
} from "../../../../actions";
import { toast } from "react-toastify";
import RelatedProduct from "./relatedProduct";
import Excl from "./exclamanation.png";
import { CircleLoader } from "react-spinners";
class DetailsWithPriceApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      quantity: 0,
      selectedColor: null,
      colorUrl: "",
      selectedSize: "",
      selectedSizeObj: "",
      stock: "InStock",
      nav3: null,
      warningMessage: false,
      skuId: "",
      sizesArray: [],
      totalPayableQuantity: 0,
      totalPrice: 0,
      roundedUp: false,
      shippingRate: 0,
      productType: "",
      shipping: "",
      submit_loader: false,
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    console.log(this.colorsArray);
    this.setState({
      nav3: this.slider3,
    });
    if (this.colorsArray.length > 0) {
      this.setState(
        {
          selectedColor: this.colorsArray[0],
          colorValue: this.colorsArray[0].value,
        },
        () => {
          if (this.sizeArray && this.sizeArray.length > 0) {
            this.getSizesArray(this.state.selectedColor);
          }
        }
      );
    }
  }

  getSizesArray = (color) => {
    const { item } = this.props;
    console.log(item);
    console.log(color);

    if (color.url) {
      let sizesArray = item.variants.filter((sizesku) => {
        let colorCode = sizesku.properties
          .split(";")
          .filter((colorSizeCode) => colorSizeCode.startsWith("0"))[0];
        if (colorCode == color.properties) {
          return true;
        } else {
          return false;
        }
      });
      this.setState(
        {
          sizesArray: sizesArray.sort(
            (a, b) =>
              a.properties.split(";")[1].split(":")[1] -
              b.properties.split(";")[1].split(":")[1]
          ),
        },
        () => {
          console.log(this.state.sizesArray);
        }
      );
    } else if (color.value) {
      let sizesArray = item.variants.filter((sizesku) => {
        let colorCode = sizesku.properties
          .split(";")
          .filter((colorSizeCode) => colorSizeCode.startsWith("0"))[0];
        if (colorCode == color.properties) {
          return true;
        } else {
          return false;
        }
      });
      this.setState(
        {
          sizesArray: sizesArray.sort(
            (a, b) =>
              a.properties.split(";")[1].split(":")[1] -
              b.properties.split(";")[1].split(":")[1]
          ),
        },
        () => {
          console.log(this.state.sizesArray);
        }
      );
    } else {
      let sizesArray = item.variants.filter((sizesku) => {
        let colorCode = sizesku.properties
          .split(";")
          .filter((colorSizeCode) => colorSizeCode.startsWith("0"))[0];
        if (colorCode == Object.keys(color)[0]) {
          return true;
        } else {
          return false;
        }
      });
      this.setState(
        {
          sizesArray: sizesArray.sort(
            (a, b) =>
              a.properties.split(";")[1].split(":")[1] -
              b.properties.split(";")[1].split(":")[1]
          ),
        },
        () => {
          console.log(this.state.sizesArray);
        }
      );
    }
  };

  minusQty = () => {
    const { item, currency } = this.props;
    if (this.state.totalPayableQuantity > 1) {
      this.setState({
        totalPayableQuantity: parseInt(this.state.totalPayableQuantity) - 1,
        totalPrice:
          Math.round(parseFloat(item.price) * parseFloat(currency.taka)) *
          (parseInt(this.state.totalPayableQuantity) - 1),
      });
    }
  };

  plusQty = () => {
    const { item, currency } = this.props;
    this.setState({
      totalPayableQuantity: parseInt(this.state.totalPayableQuantity) + 1,
      totalPrice:
        Math.round(parseFloat(item.price) * parseFloat(currency.taka)) *
        (parseInt(this.state.totalPayableQuantity) + 1),
    });
  };

  changeQty = (e) => {
    const { item, currency } = this.props;
    e.preventDefault();
    const { name, value } = e.target;
    console.log(typeof value);
    this.setState(
      {
        [name]: value == "" ? 0 : value.startsWith(0) ? value.slice(1) : value,
      },
      () => {
        this.setState({
          totalPrice:
            Math.round(parseFloat(item.price) * parseFloat(currency.taka)) *
            parseInt(this.state.totalPayableQuantity),
        });
      }
    );
  };

  changeSkuQty = (e) => {
    const { item, currency } = this.props;
    e.preventDefault();
    const { name, value } = e.target;

    if (String(value).startsWith("0")) {
      this.setState(
        {
          [name]: String(value).replace("0", ""),
        },
        () => {
          let skuKeyArray = Object.keys(this.state)
            .filter((key) => key.startsWith("0") || key.startsWith("1"))
            .filter((key) => this.state[key] !== "");
          let totalQuantity = 0;
          let totalPrice = 0;
          console.log(skuKeyArray);

          skuKeyArray.map((key) => {
            let sku = item.variants.find((sku) => sku.properties == key);
            totalQuantity += parseInt(this.state[key]);
            totalPrice +=
              Math.round(parseFloat(sku.price) * parseFloat(currency.taka)) *
              parseInt(this.state[key]);
            console.log(totalQuantity);
          });
          this.setState(
            {
              totalPayableQuantity: parseInt(totalQuantity),
              totalPrice: parseInt(totalPrice),
            },
            () => {
              console.log(this.state.totalPayableQuantity);
              console.log(this.state.totalPrice);
            }
          );
        }
      );
    } else {
      this.setState(
        {
          [name]: String(value),
        },
        () => {
          let skuKeyArray = Object.keys(this.state)
            .filter((key) => key.startsWith("0") || key.startsWith("1"))
            .filter((key) => this.state[key] !== "");
          let totalQuantity = 0;
          let totalPrice = 0;
          console.log(skuKeyArray);
          skuKeyArray.map((key) => {
            let sku = item.variants.find((sku) => sku.properties == key);
            totalQuantity += parseInt(this.state[key]);
            totalPrice +=
              Math.round(parseFloat(sku.price) * parseFloat(currency.taka)) *
              parseInt(this.state[key]);
            console.log(totalQuantity);
          });
          this.setState(
            {
              totalPayableQuantity: parseInt(totalQuantity),
              totalPrice: parseInt(totalPrice),
            },
            () => {
              console.log(this.state.totalPayableQuantity);
              console.log(this.state.totalPrice);
            }
          );
        }
      );
    }
  };

  clickOnColorVariant = (color) => {
    if (color.url) {
      this.props.clickOnColorVariant(color.url);
      this.setState({ colorUrl: color.url, colorValue: color.value });
    }

    this.setState({ selectedColor: color, colorValue: color.value }, () => {
      let colorCode = color.properties;
      // if (color.url) {
      //   colorCode = color.properties;
      // } else {
      //   colorCode = Object.keys(color)[0];
      // }

      this.getSizesArray(color);
    });
  };

  getSkuId = (image, size) => {
    console.log("getSkuId is getting called");
    console.log(image, size);
    if (image && size) {
      console.log(`${Object.keys(size)[0]};${image}`);
      const skuId = this.props.item.variants.find(
        (variantObj) =>
          variantObj.properties == `${Object.keys(size)[0]};${image}`
      );
      this.setState({ skuId }, () => console.log(this.state.skuId));
      // console.log(skuId);
    }
    if (image && !size) {
      const skuId = this.props.item.variants.find(
        (variantObj) => variantObj.properties == `${image}`
      );
      this.setState({ skuId }, () => console.log(this.state.skuId));
    }
    if (size && !image) {
      const skuId = this.props.item.variants.find(
        (variantObj) => variantObj.properties == `${Object.keys(size)[0]}`
      );
      this.setState({ skuId }, () => console.log(this.state.skuId));
    }
  };

  BuynowClicked = (price2, total) => {
    const {
      BuynowClicked,
      history,
      currentUser,
      addToPendingOrdersRedux,
      item,
      currency,
    } = this.props;

    if (item.price_range) {
      if (parseInt(total) < 1000) {
        alert("Your order value must be equal to or more than 1000Tk");
        return;
      }
    } else {
      if (parseInt(this.state.totalPrice) < 1000) {
        alert("Your order value must be equal to or more than 1000Tk");
        return;
      }
    }
    if (!this.state.productType || this.state.shippingRate == 0) {
      alert("Please choose your product type");
      return;
    }

    let skuKeyArray = Object.keys(this.state)
      .filter((key) => key.startsWith("0") || key.startsWith("1"))
      .filter((key) => this.state[key] !== "");

    if (skuKeyArray.length == 0 && this.state.totalPayableQuantity == 0) {
      this.setState({
        warningMessage: true,
      });
      return;
    }
    if (this.state.sizesArray.length == 0 && this.colorsArray.length == 0) {
      let price;
      if (item.price_range.length > 0) {
        price = price2;
      } else {
        price = Math.round(parseFloat(item.price) * parseFloat(currency.taka));
      }
      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: [],
            skus: [
              {
                totalQuantity: this.state.totalPayableQuantity,
                price: price,
                sku_id: new Date().getTime().toString(),
              },
            ],
            propertyNames: item.propertyNames,
            price_range: item.price_range
              ? JSON.stringify(item.price_range)
              : "",
            shippingRate: this.state.shippingRate,
            productType: this.state.productType,
            weight: parseFloat(
              item.item_weight * this.state.totalPayableQuantity
            ).toFixed(2),
          },
        ],
      };
      console.log(cartItemObj);
      addToPendingOrdersRedux([cartItemObj]);
      this.props.history.push("/dashboard/pages/place-order");
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
        totalPayableQuantity: 0,
        totalPrice: 0,
        productType: "",
        shipping: "",
      });
    } else {
      let totalQuantity = 0;
      let skus = skuKeyArray.map((key) => {
        let sku = item.variants.find((sku) => sku.properties == key);
        let color = this.getColorName(sku.properties_name);
        let image = this.getImage2(sku.properties);
        let size = this.getSizeName(sku.properties_name);
        let price;
        if (item.price_range.length > 0) {
          price = price2;
        } else {
          price = Math.round(parseFloat(sku.price) * parseFloat(currency.taka));
        }

        totalQuantity += this.state[key];
        return {
          totalQuantity: this.state[key],
          color,
          size,
          image,
          ...sku,
          price,
        };
      });
      if (totalQuantity == 0) {
        this.setState({
          warningMessage: true,
        });
        return;
      }

      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: false,
            skus,
            propertyNames: item.propertyNames,
            price_range: item.price_range
              ? JSON.stringify(item.price_range)
              : "",
            shippingRate: this.state.shippingRate,
            productType: this.state.productType,
            weight: parseFloat(
              item.item_weight * this.state.totalPayableQuantity
            ).toFixed(2),
          },
        ],
      };
      console.log(cartItemObj);
      addToPendingOrdersRedux([cartItemObj]);
      this.props.history.push("/dashboard/pages/place-order");
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
        totalPayableQuantity: 0,
        productType: "",
        shipping: "",
      });
      skuKeyArray.map((key) => {
        this.setState({
          [key]: "",
        });
      });
    }
  };

  getSizeName = (name) => {
    let array = name.split(";");
    let sizeStr = array.find((str) => str.startsWith("1"));
    if (sizeStr) {
      let sizeArry = sizeStr.split(":");
      let size = sizeArry[sizeArry.length - 1];
      return size;
    } else {
      return null;
    }
  };

  getColorName = (name) => {
    let array = name.split(";");
    let colorStr = array.find((str) => str.startsWith("0"));
    if (colorStr) {
      let colorArry = colorStr.split(":");
      let color = colorArry[colorArry.length - 1];
      return color;
    } else {
      return null;
    }
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    const [productType, shippingRate] = value.split("-");
    this.setState({ productType, shippingRate, [name]: value });
  };

  addToCartClicked = async (price2, total) => {
    const {
      currentUser,
      addToCartClicked,
      BuynowClicked,
      history,
      item,
      currency,
    } = this.props;
    if (item.price_range) {
      if (parseInt(total) < 1000) {
        alert("Your order value must be equal to or more than 1000Tk");
        return;
      }
    } else {
      if (parseInt(this.state.totalPrice) < 1000) {
        alert("Your order value must be equal to or more than 1000Tk");
        return;
      }
    }
    if (!this.state.productType || this.state.shippingRate == 0) {
      alert("Please choose your product type");
      return;
    }

    let skuKeyArray = Object.keys(this.state)
      .filter((key) => key.startsWith("0") || key.startsWith("1"))
      .filter((key) => this.state[key] !== "");

    if (skuKeyArray.length == 0 && this.state.totalPayableQuantity == 0) {
      this.setState({
        warningMessage: true,
      });
      return;
    }
    if (this.state.sizesArray.length == 0 && this.colorsArray.length == 0) {
      let price;
      if (item.price_range.length > 0) {
        price = price2;
      } else {
        price = Math.round(parseFloat(item.price) * parseFloat(currency.taka));
      }
      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: [],

            skus: [
              {
                totalQuantity: this.state.totalPayableQuantity,
                price: price,
                sku_id: new Date().getTime().toString(),
              },
            ],
            propertyNames: item.propertyNames,
            price_range: item.price_range
              ? JSON.stringify(item.price_range)
              : "",
            shippingRate: this.state.shippingRate,
            productType: this.state.productType,
            weight: parseFloat(
              item.item_weight * this.state.totalPayableQuantity
            ).toFixed(2),
          },
        ],
      };
      console.log(cartItemObj);
      await addToCartClicked(cartItemObj);
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        totalPayableQuantity: 0,
        totalPrice: 0,
        productType: "",
        colorUrl: "",
        shipping: "",
      });
    } else {
      let totalQuantity = 0;
      let skus = skuKeyArray.map((key) => {
        let sku = item.variants.find((sku) => sku.properties == key);
        let color = this.getColorName(sku.properties_name);
        let image = this.getImage2(sku.properties);
        let size = this.getSizeName(sku.properties_name);

        let price;
        if (item.price_range.length > 0) {
          price = price2;
        } else {
          price = Math.round(parseFloat(sku.price) * parseFloat(currency.taka));
        }

        totalQuantity += this.state[key];
        return {
          totalQuantity: this.state[key],
          color,
          size,
          image,
          ...sku,
          price,
        };
      });

      if (totalQuantity == 0) {
        this.setState({
          warningMessage: true,
        });
        return;
      }

      let cartItemObj = {
        shopId: item.shop_id,
        shopName: item.shop_name,
        items: [
          {
            id: item.id,
            name: item.name,
            detail_url: item.detail_url,
            availability: item.availability,
            picture: item.pictures[0],
            variants: false,
            skus,
            propertyNames: item.propertyNames,
            price_range: item.price_range
              ? JSON.stringify(item.price_range)
              : "",
            shippingRate: this.state.shippingRate,
            productType: this.state.productType,
            weight: parseFloat(
              item.item_weight * this.state.totalPayableQuantity
            ).toFixed(2),
          },
        ],
      };
      console.log("add to cart clicked !");
      await addToCartClicked(cartItemObj);
      this.setState({
        open: false,
        quantity: 1,
        selectedSize: "",
        stock: "InStock",
        nav3: null,
        warningMessage: false,
        colorUrl: "",
        totalPayableQuantity: 0,
        totalPrice: 0,
        productType: "",
        shipping: "",
      });
      skuKeyArray.map((key) => {
        this.setState({
          [key]: "",
        });
      });
    }
  };

  getImage = (colorSizeCode) => {
    const { item } = this.props;

    let colorCode = colorSizeCode
      .split(";")
      .find((color) => color.startsWith("0"));

    const colorObj = this.colorsArray.find(
      (color) =>
        (color.properties && color.properties == colorCode) ||
        Object.keys(color)[0] == colorCode
    );
    if (colorObj) {
      if (colorObj.url) {
        return (
          <div
            style={{
              maxWidth: "52px",
              minWidth: "52px",
              minHeight: "52px",
              maxHeight: "52px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => this.props.clickOnColorVariant(colorObj.url)}
          >
            <img
              src={colorObj.url}
              alt={item.properties}
              className="img-fluid color-variant-image"
              style={{
                maxWidth: "50px",
                minHeight: "50px",
                maxHeight: "50px",
                minWidth: "50px",
                border: "2px solid gainsboro",
                borderRadius: 5,
              }}
            />
          </div>
        );
      } else if (colorObj.value) {
        return (
          <div
            style={{
              padding: 5,
              fontSize: 10,
              border: "1px solid gainsboro",
              display: "inline-block",
            }}
          >
            {colorObj.value}
          </div>
        );
      } else {
        return (
          <div
            style={{
              padding: 5,
              fontSize: 12,
              border: "1px solid gainsboro",
              display: "inline-block",
            }}
          >
            {Object.values(colorObj)[0]}
          </div>
        );
      }
    } else {
      return null;
    }
  };
  getImage2 = (colorSizeCode) => {
    const { item } = this.props;

    let colorCode = colorSizeCode
      .split(";")
      .find((color) => color.startsWith("0"));

    const colorObj = this.colorsArray.find(
      (color) =>
        (color.properties && color.properties == colorCode) ||
        Object.keys(color)[0] == colorCode
    );
    if (colorObj) {
      if (colorObj.url) {
        return colorObj.url;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  updateTotalPrice = () => {
    const { item, currency } = this.props;
    let skuKeyArray = Object.keys(this.state)
      .filter((key) => key.startsWith("0") || key.startsWith("1"))
      .filter((key) => this.state[key] !== "");
    let totalQuantity = 0;
    let totalPrice = 0;
    console.log(skuKeyArray);
    skuKeyArray.map((key) => {
      let sku = item.variants.find((sku) => sku.properties == key);
      totalPrice +=
        Math.round(parseFloat(sku.price) * parseFloat(currency.taka)) *
        parseInt(this.state[key]);
      console.log(totalQuantity);
    });
    this.setState({
      totalPrice: parseInt(totalPrice),
    });
  };

  render() {
    const {
      symbol,
      item,
      BuynowClicked,
      addToWishlistClicked,
      currentUser,
      currency,
      d2dRates,
    } = this.props;

    var colorsnav = {
      slidesToShow: 8,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };

    var totalAvailableQuantity = 0;
    if (item.variants.length > 0) {
      item.variants.map(
        (item) => (totalAvailableQuantity += parseInt(item.quantity))
      );
    }
    console.log(item.variants);
    this.colorsArray = [];
    if (item.props_imgs && item.props_imgs.prop_img.length > 0) {
      let colorsWithPicture = item.props_imgs.prop_img;
      let colorsWithoutPicture = Object.keys(item.props_list)
        .filter((key) => key.startsWith("0"))
        .map((key) => {
          return { properties: key, value: item.props_list[key] };
        });
      if (colorsWithPicture.length == colorsWithoutPicture.length) {
        this.colorsArray = colorsWithPicture;
      } else {
        let mergedArray = [...colorsWithPicture, ...colorsWithoutPicture];

        var resArr = [];
        mergedArray.filter((item) => {
          var i = resArr.findIndex((x) => x.properties == item.properties);
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });

        this.colorsArray = resArr;
      }
    } else if (item.prop_imgs && item.prop_imgs.prop_img.length > 0) {
      let colorsWithPicture = item.prop_imgs.prop_img;
      let colorsWithoutPicture = Object.keys(item.props_list)
        .filter((key) => key.startsWith("0"))
        .map((key) => {
          return { properties: key, value: item.props_list[key] };
        });
      if (colorsWithPicture.length == colorsWithoutPicture.length) {
        this.colorsArray = colorsWithPicture;
      } else {
        let mergedArray = [...colorsWithPicture, ...colorsWithoutPicture];

        var resArr = [];
        mergedArray.filter((item) => {
          var i = resArr.findIndex((x) => x.properties == item.properties);
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });

        this.colorsArray = resArr;
      }
    } else if (
      item.props_list &&
      Object.keys(item.props_list).filter((key) => key.startsWith("0")).length >
        0
    ) {
      this.colorsArray = Object.keys(item.props_list)
        .filter((key) => key.startsWith("0"))
        .map((key) => {
          return { properties: key, value: item.props_list[key] };
        });
    }

    this.sizeArray = [];
    if (item.props_list) {
      this.sizeArray = Object.keys(item.props_list)
        .filter((key) => key.startsWith("1"))
        .map((key) => {
          return { [key]: item.props_list[key] };
        });
    }

    let taka = 0;
    if (currency) {
      taka = currency.taka;
    }

    let RatingStars = [];
    for (var i = 0; i < 5; i++) {
      RatingStars.push(
        <i
          className="fa fa-star"
          key={i}
          style={{ color: item.rating > i ? "#ffa200" : "#dddddd" }}
        />
      );
    }
    let RatingStars2 = [];
    for (var i = 0; i < item.shop_rating; i++) {
      RatingStars2.push(
        <i className="fa fa-star" style={{ color: "darkorange" }} key={i} />
      );
    }

    let price2 = parseInt(item.price * parseFloat(taka));
    if (item.price_range && item.price_range.length > 0) {
      item.price_range.map((price, index, arr) => {
        const stateQ = parseInt(this.state.totalPayableQuantity);
        const dataQs = arr.map((p, i) => (i === 0 ? 0 : parseInt(p[0])));
        const selectedIndex = dataQs.findIndex((q, i, arr) => {
          return stateQ >= q && stateQ < (arr[i + 1] || stateQ + 1);
        });
        const selected = selectedIndex === index;
        if (selected) {
          price2 = Math.round(parseFloat(price[1]) * parseFloat(taka));
        }
      });
    }
    let d2dRate = null;
    if (d2dRates.length > 0 && this.state.productType !== "") {
      d2dRate = d2dRates.find((rate) => rate.id == this.state.productType);
    }
    return (
      <div className="col-lg-6 rtl-text" style={{ padding: 0 }}>
        <div className="product-right">
          <h6 className="product-detail-title">
            {" "}
            {window.innerWidth > 600
              ? item.name
              : `${item.name.slice(0, 60)}...`}
          </h6>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingBottom: 10,
            }}
          >
            <p>within 30 days</p>
            <p style={{ paddingLeft: 20 }}>
              The purchase of this product is a commercial trade activity
            </p>
          </div> */}
          {item.price_range.length > 0 ? (
            <div className="price-range-container">
              {item.price_range.map((price, index, arr) => {
                const stateQ = parseInt(this.state.totalPayableQuantity);
                const dataQs = arr.map((p, i) =>
                  i === 0 ? 0 : parseInt(p[0])
                );
                const selectedIndex = dataQs.findIndex((q, i, arr) => {
                  return stateQ >= q && stateQ < (arr[i + 1] || stateQ + 1);
                });
                const selected = selectedIndex === index;

                return (
                  <div
                    key={index}
                    className={`price-box ${selected && "selected"}`}
                  >
                    <h3 className="price">
                      Tk {Math.round(parseFloat(price[1]) * parseFloat(taka))}
                    </h3>
                    <p className="quantity">
                      {index == 0 && price[0] != 1 ? "below " : ""}
                      {Math.round(parseFloat(price[0]))} or more
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div>
            <h6 style={{ marginBottom: "0px", fontSize: 13 }}>
              total sold: {item.totalSold}
            </h6>
            <h6 style={{ marginBottom: "0px", fontSize: 13 }}>
              orders: {item.orders}
            </h6>
          </div>
          <div className="rating" style={{ minHeight: "35px" }}>
            {RatingStars.length > 0 ? (
              RatingStars
            ) : (
              <p
                style={{
                  color: "gainsboro",
                  fontSize: "90%",
                }}
              >
                No rating
              </p>
            )}
          </div>
          {/* for showing price  */}
          {this.colorsArray.length == 0 && this.state.sizesArray.length == 0 && (
            <h3 style={{ color: "#f48110" }}>
              {symbol}
              {Math.round(parseFloat(item.price) * parseFloat(taka))}
              <span />
            </h3>
          )}

          {this.colorsArray.length > 0 && this.state.sizesArray.length > 0 ? (
            <div style={{ marginBottom: 40 }}>
              <h6
                className="product-title size-text"
                style={{ marginBottom: 10 }}
              >
                {item.propertyNames[0]}: {this.state.colorValue}{" "}
              </h6>
              <div
                className="d-flex flex-row color-container"
                style={{ marginBottom: 20, rowGap: 20, flexWrap: "wrap" }}
              >
                {this.colorsArray.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        padding: 2,
                        cursor: "pointer",
                        border:
                          item.url &&
                          this.state.selectedColor &&
                          this.state.selectedColor.properties == item.properties
                            ? "2px solid #18768f"
                            : "2px solid gainsboro",
                        marginLeft: 6,
                        borderRadius: 5,
                      }}
                    >
                      {item.url ? (
                        <div
                          key={i}
                          style={{
                            maxWidth: "50px",
                            minWidth: "50px",
                            minHeight: "52px",
                            maxHeight: "52px",
                            padding: "0px",
                          }}
                          onClick={() => this.clickOnColorVariant(item)}
                        >
                          <img
                            src={`${item.url}`}
                            key={i}
                            alt={item.properties}
                            className="img-fluid color-variant-image"
                            style={{
                              maxWidth: "50px",
                              minWidth: "50px",
                              minHeight: "50px",
                              maxHeight: "50px",
                            }}
                          />
                          <div
                            style={{
                              fontSize: 10,
                              marginTop: 5,
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            {item.value.slice(0, 8)}
                          </div>
                        </div>
                      ) : (
                        <div
                          key={i}
                          style={{
                            minHeight: "32px",
                            maxHeight: "32px",
                            padding: "5px",
                            border:
                              this.state.selectedColor &&
                              this.state.selectedColor.properties ==
                                item.properties
                                ? "2px solid #18768f"
                                : "2px solid gainsboro",
                            fontSize: 12,
                            color:
                              this.state.selectedColor &&
                              this.state.selectedColor.properties ==
                                item.properties
                                ? "black"
                                : "gray",
                            borderRadius: 5,
                          }}
                          onClick={() => this.clickOnColorVariant(item)}
                        >
                          {" "}
                          {item.value}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : this.colorsArray.length > 0 ? (
            <>
              <div
                className="d-flex flex-row"
                style={{
                  padding: "20px 10px",

                  flexWrap: "nowrap",
                  borderBottom: "1px dotted gainsboro",
                  background: "beige",
                }}
                key={i}
              >
                <div
                  className="size-price size-price2 col"
                  style={{ fontWeight: "bold", fontSize: 12 }}
                >
                  {item.propertyNames[0]}
                </div>
                <div
                  className="size-price col"
                  style={{
                    fontWeight: "bold",
                    margin: "auto 0px",
                    fontSize: 12,
                  }}
                >
                  Price
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "auto 0px",
                    fontSize: 12,
                  }}
                  className="size-price col"
                >
                  Stock
                </div>

                <div
                  style={{
                    margin: "auto 0px",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 12,
                  }}
                  className="size-price col-5"
                >
                  Quantity
                </div>
              </div>
              {item.variants.slice(0, 3).map((color, i) => (
                <div
                  className="d-flex flex-row"
                  style={{
                    padding: "20px 10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    borderBottom: "1px dotted gainsboro",
                  }}
                  key={i}
                >
                  <div className="size-price size-price2 col">
                    {this.getImage(color.properties)}
                  </div>
                  <div
                    className="size-price col"
                    style={{
                      fontWeight: "bold",
                      margin: "auto 0px",
                      textAlign: "center",
                    }}
                  >
                    Tk.{" "}
                    {item.price_range
                      ? price2
                      : Math.round(parseFloat(color.price) * parseFloat(taka))}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      margin: "auto 0px",
                    }}
                    className="size-price col"
                  >
                    {color.quantity}
                  </div>

                  <div
                    style={{ margin: "auto 0px" }}
                    className="size-price col-5"
                  >
                    <div
                      className="qty-box"
                      style={{ marginTop: 0, justifyContent: "center" }}
                    >
                      {color.quantity == "0" ? (
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "lighter",
                            fontSize: 12,
                            marginTop: 5,
                            display: "block",
                            textAlign: "center",
                          }}
                        >
                          Out of Stock
                        </span>
                      ) : (
                        <div className="input-group">
                          <span
                            className="input-group-prepend"
                            style={{ height: 25 }}
                          >
                            <button
                              type="button"
                              className="btn quantity-left-minus"
                              onClick={() => {
                                if (color.properties) {
                                  if (this.state[color.properties] != 0) {
                                    this.setState(
                                      {
                                        [color.properties]:
                                          parseInt(
                                            this.state[color.properties]
                                          ) - 1,
                                        totalPayableQuantity:
                                          parseInt(
                                            this.state.totalPayableQuantity
                                          ) - 1,
                                      },
                                      () => {
                                        this.updateTotalPrice();
                                      }
                                    );
                                  }
                                } else {
                                  if (this.state[Object.keys(color)[0]] != 0) {
                                    this.setState(
                                      {
                                        [Object.keys(color)[0]]:
                                          parseInt(
                                            this.state[Object.keys(color)[0]]
                                          ) - 1,
                                        totalPayableQuantity:
                                          parseInt(
                                            this.state.totalPayableQuantity
                                          ) - 1,
                                      },
                                      () => {
                                        this.updateTotalPrice();
                                      }
                                    );
                                  }
                                }
                              }}
                              data-type="minus"
                              data-field=""
                            >
                              <i className="icofont-minus" />
                            </button>
                          </span>
                          {color.properties ? (
                            <input
                              type="number"
                              name={`${color.properties}`}
                              value={
                                this.state[`${color.properties}`]
                                  ? this.state[`${color.properties}`]
                                  : 0
                              }
                              onChange={this.changeSkuQty}
                              className="form-control input-number"
                              style={{ height: 25 }}
                              placeholder="0"
                            />
                          ) : (
                            <input
                              type="number"
                              name={`${Object.keys(color)[0]}`}
                              value={
                                this.state[`${Object.keys(color)[0]}`]
                                  ? this.state[`${Object.keys(color)[0]}`]
                                  : 0
                              }
                              onChange={this.changeSkuQty}
                              className="form-control input-number"
                              style={{ height: 25 }}
                            />
                          )}

                          <span
                            className="input-group-prepend"
                            style={{ height: 25 }}
                          >
                            <button
                              type="button"
                              className="btn quantity-right-plus"
                              onClick={() => {
                                if (color.properties) {
                                  if (!this.state[color.properties]) {
                                    this.setState(
                                      {
                                        [color.properties]: 1,
                                        totalPayableQuantity:
                                          parseInt(
                                            this.state.totalPayableQuantity
                                          ) + 1,
                                      },
                                      () => {
                                        this.updateTotalPrice();
                                      }
                                    );
                                  } else {
                                    this.setState(
                                      {
                                        [color.properties]:
                                          parseInt(
                                            this.state[color.properties]
                                          ) + 1,
                                        totalPayableQuantity:
                                          parseInt(
                                            this.state.totalPayableQuantity
                                          ) + 1,
                                      },
                                      () => {
                                        this.updateTotalPrice();
                                      }
                                    );
                                  }
                                } else {
                                  if (!this.state[Object.keys(color)[0]]) {
                                    this.setState(
                                      {
                                        [Object.keys(color)[0]]: 1,
                                        totalPayableQuantity:
                                          parseInt(
                                            this.state.totalPayableQuantity
                                          ) + 1,
                                      },
                                      () => {
                                        this.updateTotalPrice();
                                      }
                                    );
                                  } else {
                                    this.setState(
                                      {
                                        [Object.keys(color)[0]]:
                                          parseInt(
                                            this.state[Object.keys(color)[0]]
                                          ) + 1,
                                        totalPayableQuantity:
                                          parseInt(
                                            this.state.totalPayableQuantity
                                          ) + 1,
                                      },
                                      () => {
                                        this.updateTotalPrice();
                                      }
                                    );
                                  }
                                }
                              }}
                              data-type="plus"
                              data-field=""
                            >
                              <i className="icofont-plus" />
                            </button>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="accordion theme-accordion" id="accordion">
                <div className="card" style={{ border: "none" }}>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body" style={{ padding: "0px" }}>
                      {item.variants.slice(3).map((color, i) => (
                        <div
                          className="d-flex flex-row"
                          style={{
                            padding: "20px 10px",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            borderBottom: "1px dotted gainsboro",
                          }}
                          key={i}
                        >
                          <div className="size-price size-price2 col">
                            {this.getImage(color.properties)}
                          </div>
                          <div
                            className="size-price col"
                            style={{
                              fontWeight: "bold",
                              margin: "auto 0px",
                              textAlign: "center",
                            }}
                          >
                            Tk.{" "}
                            {item.price_range
                              ? price2
                              : Math.round(
                                  parseFloat(color.price) * parseFloat(taka)
                                )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: 10,
                              margin: "auto 0px",
                            }}
                            className="size-price col"
                          >
                            {color.quantity}
                          </div>

                          <div
                            style={{ margin: "auto 0px" }}
                            className="size-price col-5"
                          >
                            <div
                              className="qty-box"
                              style={{ marginTop: 0, justifyContent: "center" }}
                            >
                              {color.quantity == "0" ? (
                                <span
                                  style={{
                                    color: "gray",
                                    fontWeight: "lighter",
                                    fontSize: 12,
                                    marginTop: 5,
                                    display: "block",
                                    textAlign: "center",
                                  }}
                                >
                                  Out of Stock
                                </span>
                              ) : (
                                <div className="input-group">
                                  <span
                                    className="input-group-prepend"
                                    style={{ height: 25 }}
                                  >
                                    <button
                                      type="button"
                                      className="btn quantity-left-minus"
                                      onClick={() => {
                                        if (color.properties) {
                                          if (
                                            this.state[color.properties] != 0
                                          ) {
                                            this.setState(
                                              {
                                                [color.properties]:
                                                  parseInt(
                                                    this.state[color.properties]
                                                  ) - 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) - 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          }
                                        } else {
                                          if (
                                            this.state[Object.keys(color)[0]] !=
                                            0
                                          ) {
                                            this.setState(
                                              {
                                                [Object.keys(color)[0]]:
                                                  parseInt(
                                                    this.state[
                                                      Object.keys(color)[0]
                                                    ]
                                                  ) - 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) - 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          }
                                        }
                                      }}
                                      data-type="minus"
                                      data-field=""
                                    >
                                      <i className="icofont-minus" />
                                    </button>
                                  </span>
                                  {color.properties ? (
                                    <input
                                      type="number"
                                      name={`${color.properties}`}
                                      value={
                                        this.state[`${color.properties}`]
                                          ? this.state[`${color.properties}`]
                                          : 0
                                      }
                                      onChange={this.changeSkuQty}
                                      className="form-control input-number"
                                      style={{ height: 25 }}
                                      placeholder="0"
                                    />
                                  ) : (
                                    <input
                                      type="number"
                                      name={`${Object.keys(color)[0]}`}
                                      value={
                                        this.state[`${Object.keys(color)[0]}`]
                                          ? this.state[
                                              `${Object.keys(color)[0]}`
                                            ]
                                          : 0
                                      }
                                      onChange={this.changeSkuQty}
                                      className="form-control input-number"
                                      style={{ height: 25 }}
                                    />
                                  )}

                                  <span
                                    className="input-group-prepend"
                                    style={{ height: 25 }}
                                  >
                                    <button
                                      type="button"
                                      className="btn quantity-right-plus"
                                      onClick={() => {
                                        if (color.properties) {
                                          if (!this.state[color.properties]) {
                                            this.setState(
                                              {
                                                [color.properties]: 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) + 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          } else {
                                            this.setState(
                                              {
                                                [color.properties]:
                                                  parseInt(
                                                    this.state[color.properties]
                                                  ) + 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) + 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          }
                                        } else {
                                          if (
                                            !this.state[Object.keys(color)[0]]
                                          ) {
                                            this.setState(
                                              {
                                                [Object.keys(color)[0]]: 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) + 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          } else {
                                            this.setState(
                                              {
                                                [Object.keys(color)[0]]:
                                                  parseInt(
                                                    this.state[
                                                      Object.keys(color)[0]
                                                    ]
                                                  ) + 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) + 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          }
                                        }
                                      }}
                                      data-type="plus"
                                      data-field=""
                                    >
                                      <i className="icofont-plus" />
                                    </button>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="card-header"
                    id="headingOne"
                    style={{ background: "white", border: "none" }}
                  >
                    <h5 className="mb-0">
                      <div
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        style={{
                          color: "#18768f",
                          display: "block",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          this.setState({
                            roundedUp: !this.state.roundedUp,
                          });
                        }}
                      >
                        View all{" "}
                        {this.state.roundedUp ? (
                          <i className="icofont-rounded-up" />
                        ) : (
                          <i className="icofont-rounded-down" />
                        )}
                      </div>
                    </h5>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="product-description border-product">
            {item ? (
              <div>
                <div className="size-box">
                  {this.state.sizesArray.length > 0 ? (
                    <div className="size-category">
                      <div
                        className="d-flex flex-row"
                        style={{
                          padding: "20px 10px",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          borderBottom: "1px dotted gainsboro",
                          background: "beige",
                        }}
                        key={i}
                      >
                        <div
                          className="size-price size-price2 col"
                          style={{ fontWeight: "bold", fontSize: 12 }}
                        >
                          {item.propertyNames[1]}
                        </div>
                        <div
                          className="size-price col"
                          style={{
                            fontWeight: "bold",
                            margin: "auto 0px",
                            fontSize: 12,
                            textAlign: "center",
                          }}
                        >
                          Price
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "auto 0px",
                            fontSize: 12,
                          }}
                          className="size-price col"
                        >
                          Stock
                        </div>

                        <div
                          style={{
                            margin: "auto 0px",
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: 12,
                          }}
                          className="size-price col-5"
                        >
                          Quantity
                        </div>
                      </div>
                      {this.state.sizesArray.slice(0, 3).map((sizeObj, i) => {
                        return (
                          <div
                            className="d-flex flex-row"
                            style={{
                              padding: "20px 10px",
                              display: "flex",
                              flexDirection: "row",
                              borderBottom: "1px dotted gainsboro",
                            }}
                            key={i}
                          >
                            <div className="col size-price">
                              {this.getSizeName(sizeObj.properties_name)}
                            </div>
                            <div
                              className="size-price col"
                              style={{
                                fontWeight: "bold",
                                margin: "auto 0px",
                                textAlign: "center",
                              }}
                            >
                              Tk.{" "}
                              {item.price_range
                                ? price2
                                : Math.round(
                                    parseFloat(sizeObj.price) * parseFloat(taka)
                                  )}
                            </div>

                            <div
                              style={{
                                textAlign: "center",
                                fontSize: 10,
                                margin: "auto 0px",
                              }}
                              className="size-price col"
                            >
                              {sizeObj.quantity} <br />
                            </div>

                            <div
                              style={{ margin: "auto 0px" }}
                              className="size-price col-5"
                            >
                              <div
                                className="qty-box"
                                style={{
                                  marginTop: 0,
                                  justifyContent: "center",
                                }}
                              >
                                {sizeObj.quantity == "0" ? (
                                  <span
                                    style={{
                                      color: "gray",
                                      fontWeight: "lighter",
                                      fontSize: 12,
                                      marginTop: 5,
                                      display: "block",
                                      textAlign: "center",
                                    }}
                                  >
                                    Out of Stock
                                  </span>
                                ) : (
                                  <div className="input-group">
                                    <span
                                      className="input-group-prepend"
                                      style={{ height: 25 }}
                                    >
                                      <button
                                        type="button"
                                        className="btn quantity-left-minus"
                                        onClick={() => {
                                          if (
                                            this.state[sizeObj.properties] != 0
                                          ) {
                                            this.setState(
                                              {
                                                [sizeObj.properties]:
                                                  parseInt(
                                                    this.state[
                                                      sizeObj.properties
                                                    ]
                                                  ) - 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) - 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          }
                                        }}
                                        data-type="minus"
                                        data-field=""
                                      >
                                        <i className="icofont-minus" />
                                      </button>
                                    </span>
                                    <input
                                      type="number"
                                      name={`${sizeObj.properties}`}
                                      value={
                                        this.state[`${sizeObj.properties}`]
                                          ? String(
                                              this.state[
                                                `${sizeObj.properties}`
                                              ]
                                            )
                                          : 0
                                      }
                                      onChange={this.changeSkuQty}
                                      className="form-control input-number"
                                      style={{ height: 25 }}
                                      placeholder={"0"}
                                    />
                                    <span
                                      className="input-group-prepend"
                                      style={{ height: 25 }}
                                    >
                                      <button
                                        type="button"
                                        className="btn quantity-right-plus"
                                        onClick={() => {
                                          if (!this.state[sizeObj.properties]) {
                                            this.setState(
                                              {
                                                [sizeObj.properties]: 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) + 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          } else {
                                            this.setState(
                                              {
                                                [sizeObj.properties]:
                                                  parseInt(
                                                    this.state[
                                                      sizeObj.properties
                                                    ]
                                                  ) + 1,
                                                totalPayableQuantity:
                                                  parseInt(
                                                    this.state
                                                      .totalPayableQuantity
                                                  ) + 1,
                                              },
                                              () => {
                                                this.updateTotalPrice();
                                              }
                                            );
                                          }
                                        }}
                                        data-type="plus"
                                        data-field=""
                                      >
                                        <i className="icofont-plus" />
                                      </button>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="accordion theme-accordion" id="accordion">
                        <div className="card" style={{ border: "none" }}>
                          <div
                            id="collapseOne"
                            className="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div
                              className="card-body"
                              style={{ padding: "0px" }}
                            >
                              {this.state.sizesArray
                                .slice(3)
                                .map((sizeObj, i) => {
                                  return (
                                    <div
                                      className="d-flex flex-row"
                                      style={{
                                        padding: "20px 10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        borderBottom: "1px dotted gainsboro",
                                      }}
                                      key={i}
                                    >
                                      <div className="col size-price">
                                        {this.getSizeName(
                                          sizeObj.properties_name
                                        )}
                                      </div>
                                      <div
                                        className="size-price col"
                                        style={{
                                          fontWeight: "bold",
                                          margin: "auto 0px",
                                          textAlign: "center",
                                        }}
                                      >
                                        Tk.{" "}
                                        {item.price_range
                                          ? price2
                                          : Math.round(
                                              parseFloat(sizeObj.price) *
                                                parseFloat(taka)
                                            )}
                                      </div>

                                      <div
                                        style={{
                                          textAlign: "center",
                                          fontSize: 10,
                                          margin: "auto 0px",
                                        }}
                                        className="size-price col"
                                      >
                                        {sizeObj.quantity} <br />
                                      </div>

                                      <div
                                        style={{ margin: "auto 0px" }}
                                        className="size-price col-5"
                                      >
                                        <div
                                          className="qty-box"
                                          style={{
                                            marginTop: 0,
                                            justifyContent: "center",
                                          }}
                                        >
                                          {sizeObj.quantity == "0" ? (
                                            <span
                                              style={{
                                                color: "gray",
                                                fontWeight: "lighter",
                                                fontSize: 12,
                                                marginTop: 5,
                                                display: "block",
                                                textAlign: "center",
                                              }}
                                            >
                                              Out of Stock
                                            </span>
                                          ) : (
                                            <div className="input-group">
                                              <span
                                                className="input-group-prepend"
                                                style={{ height: 25 }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn quantity-left-minus"
                                                  onClick={() => {
                                                    if (
                                                      this.state[
                                                        sizeObj.properties
                                                      ] != 0
                                                    ) {
                                                      this.setState(
                                                        {
                                                          [sizeObj.properties]:
                                                            parseInt(
                                                              this.state[
                                                                sizeObj
                                                                  .properties
                                                              ]
                                                            ) - 1,
                                                          totalPayableQuantity:
                                                            parseInt(
                                                              this.state
                                                                .totalPayableQuantity
                                                            ) - 1,
                                                        },
                                                        () => {
                                                          this.updateTotalPrice();
                                                        }
                                                      );
                                                    }
                                                  }}
                                                  data-type="minus"
                                                  data-field=""
                                                >
                                                  <i className="icofont-minus" />
                                                </button>
                                              </span>
                                              <input
                                                type="number"
                                                name={`${sizeObj.properties}`}
                                                value={
                                                  this.state[
                                                    `${sizeObj.properties}`
                                                  ]
                                                    ? String(
                                                        this.state[
                                                          `${
                                                            sizeObj.properties
                                                          }`
                                                        ]
                                                      )
                                                    : 0
                                                }
                                                onChange={this.changeSkuQty}
                                                className="form-control input-number"
                                                style={{ height: 25 }}
                                                placeholder={"0"}
                                              />
                                              <span
                                                className="input-group-prepend"
                                                style={{ height: 25 }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn quantity-right-plus"
                                                  onClick={() => {
                                                    if (
                                                      !this.state[
                                                        sizeObj.properties
                                                      ]
                                                    ) {
                                                      this.setState(
                                                        {
                                                          [sizeObj.properties]: 1,
                                                          totalPayableQuantity:
                                                            parseInt(
                                                              this.state
                                                                .totalPayableQuantity
                                                            ) + 1,
                                                        },
                                                        () => {
                                                          this.updateTotalPrice();
                                                        }
                                                      );
                                                    } else {
                                                      this.setState(
                                                        {
                                                          [sizeObj.properties]:
                                                            parseInt(
                                                              this.state[
                                                                sizeObj
                                                                  .properties
                                                              ]
                                                            ) + 1,
                                                          totalPayableQuantity:
                                                            parseInt(
                                                              this.state
                                                                .totalPayableQuantity
                                                            ) + 1,
                                                        },
                                                        () => {
                                                          this.updateTotalPrice();
                                                        }
                                                      );
                                                    }
                                                  }}
                                                  data-type="plus"
                                                  data-field=""
                                                >
                                                  <i className="icofont-plus" />
                                                </button>
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div
                            className="card-header"
                            id="headingOne"
                            style={{ background: "white", border: "none" }}
                          >
                            <h5 className="mb-0">
                              <div
                                className="btn btn-link"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                                style={{
                                  color: "#18768f",
                                  display: "block",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  this.setState({
                                    roundedUp: !this.state.roundedUp,
                                  });
                                }}
                              >
                                View all{" "}
                                {this.state.roundedUp ? (
                                  <i className="icofont-rounded-up" />
                                ) : (
                                  <i className="icofont-rounded-down" />
                                )}
                              </div>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    this.colorsArray.length == 0 &&
                    this.state.sizesArray.length == 0 && (
                      <div style={{ margin: "10px 0px" }}>
                        <div style={{ marginBottom: 5 }}>Quantity</div>
                        <div className="qty-box" style={{ marginTop: 0 }}>
                          <div className="input-group">
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-left-minus"
                                onClick={this.minusQty}
                                data-type="minus"
                                data-field=""
                              >
                                <i className="icofont-minus" />
                              </button>
                            </span>
                            <input
                              type="number"
                              name="totalPayableQuantity"
                              value={this.state.totalPayableQuantity}
                              onChange={this.changeQty}
                              className="form-control input-number"
                              placeholder="0"
                            />
                            <span className="input-group-prepend">
                              <button
                                type="button"
                                className="btn quantity-right-plus"
                                onClick={this.plusQty}
                                data-type="plus"
                                data-field=""
                              >
                                <i className="icofont-plus" />
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="shipping-info-mobile-view">
              <div
                className="local-courier-charge-div"
                style={{ marginBottom: 10, marginTop: 20 }}
              >
                <div className="form-group shipping-method">
                  <label style={{ marginBottom: 5 }}>
                    Know Your Customs & Shipping rate{" "}
                    <span style={{ fontWeight: "bold" }}>(for 5kg+)</span>:
                  </label>

                  <select
                    className="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="shipping"
                    value={this.state.shipping}
                    onChange={this.handleChange}
                    onClick={() => {
                      this.props.getAllD2DRatesRedux("air", "china");
                    }}
                  >
                    <option>Choose your product type</option>
                    {d2dRates.map((rate) => (
                      <option value={`${rate.id}-${rate.eleven}`} key={rate.id}>
                        {rate.id} - {rate.eleven}Tk/Kg
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div
                className="local-courier-charge-div"
                style={{ marginBottom: 10, marginTop: 5 }}
              >
                <div
                  className="d-flex flex-row"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    padding: 8,
                    background: "beige",
                    color: "black",
                  }}
                >
                  From China To Bangladesh
                </div>
                <div
                  className="d-flex flex-row cn-key"
                  style={{
                    textAlign: "left",
                    justifyContent: "flex-start",
                    padding: 7,
                    border: "1px solid gainsboro",
                  }}
                >
                  Total Quantity:{" "}
                  <span
                    style={{ fontWeight: "bold", marginLeft: 7, fontSize: 14 }}
                  >
                    {this.state.totalPayableQuantity} pc{" "}
                  </span>
                </div>
                <div
                  className="d-flex flex-row cn-key"
                  style={{
                    textAlign: "left",
                    justifyContent: "flex-start",
                    padding: 7,
                    border: "1px solid gainsboro",
                  }}
                >
                  Approx Weight:{" "}
                  <span
                    style={{ fontWeight: "bold", marginLeft: 7, fontSize: 14 }}
                  >
                    {parseFloat(
                      item.item_weight * this.state.totalPayableQuantity
                    ).toFixed(2)}
                    kg{"   "}
                  </span>
                  <div style={{ position: "relative" }}>
                    <img
                      className="myDIV"
                      src={Excl}
                      style={{
                        height: 18,
                        width: 18,
                        marginLeft: 6,
                        marginTop: 0,
                        cursor: "pointer",
                      }}
                    />
                    <div
                      className="hide"
                      style={{
                        position: "absolute",
                        padding: "10px",
                        borderRadius: 5,
                        border: "1px solid #18768f",
                        maxWidth: 120,
                        minWidth: 120,
                        zIndex: 100,
                        background: "beige",
                        fontSize: 11,
                        left: "30px",
                        top: "0px",
                      }}
                    >
                      {item.item_weight && item.item_weight !== 0
                        ? "এটি পণ্যের সম্ভাব্য ওজন। প্রকৃত ওজন পণ্য বাংলাদেশে আসার পর পুনরায় নির্বাচন করা হবে"
                        : "প্রকৃত ওজন পণ্য বাংলাদেশে আসার পর নির্বাচন করা হবে"}
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-row cn-key"
                  style={{
                    textAlign: "left",
                    justifyContent: "flex-start",
                    padding: 7,
                    border: "1px solid gainsboro",
                  }}
                >
                  Product Type:{" "}
                  <span
                    style={{ fontWeight: "bold", marginLeft: 7, fontSize: 14 }}
                  >
                    {this.state.productType}{" "}
                  </span>
                </div>
                <div
                  className="d-flex flex-row cn-key"
                  style={{
                    textAlign: "left",
                    justifyContent: "flex-start",
                    padding: 7,
                    border: "1px solid gainsboro",
                  }}
                >
                  Customs & Shipping Rate:
                  {this.state.shippingRate == 0 ? (
                    <span
                      style={{
                        marginLeft: 7,
                        fontSize: 14,
                      }}
                    >
                      Please choose product type first
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: 7,
                        fontSize: 14,
                      }}
                    >
                      {" "}
                      Tk {this.state.shippingRate}/kg
                    </span>
                  )}{" "}
                  {d2dRate && (
                    <div style={{ position: "relative" }}>
                      <img
                        className="myDIV2"
                        src={Excl}
                        style={{
                          height: 18,
                          width: 18,
                          marginLeft: 6,
                          marginTop: -4,
                          cursor: "pointer",
                        }}
                      />
                      <div
                        className="hide shipping-rate-hidden"
                        style={{
                          position: "absolute",
                          padding: "10px",
                          borderRadius: 5,
                          border: "1px solid #18768f",
                          maxWidth: 270,
                          minWidth: 270,
                          zIndex: 100,
                          background: "beige",
                          fontSize: 11,
                          left: "30px",
                          top: "0px",
                        }}
                      >
                        উল্লেখ্য শিপিং রেট ৫কেজির উপরের অর্ডারের জন্য। <br />
                        ৩০১গ্রাম -৫কেজির জন্য শিপিং রেট - {
                          d2dRate.ten
                        } tk/kg <br />
                        ৩০০ গ্রামের নিচের পার্সেলের শিপিং রেট - {
                          d2dRate.parcel
                        }{" "}
                        tk
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    padding: 7,
                    border: "1px solid gainsboro",
                    paddingBottom: 23,
                  }}
                >
                  <div
                    className="d-flex flex-row cn-key"
                    style={{
                      textAlign: "left",
                      justifyContent: "flex-start",
                    }}
                  >
                    Total Cost:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: 7,
                        fontSize: 14,
                      }}
                    >
                      Tk{" "}
                      {item.price_range
                        ? Math.round(price2 * this.state.totalPayableQuantity)
                        : this.state.totalPrice}
                    </span>{" "}
                  </div>
                  <div
                    style={{ float: "right", fontSize: 11, fontWeight: "bold" }}
                    className="china-local"
                  >
                    {" "}
                    + china local courier charge + customs & shipping charge
                  </div>
                </div>
              </div>
              <div className="local-courier-charge-div">
                <div
                  className="d-flex flex-row order-details-container-box"
                  style={{
                    background: "aliceblue",
                    padding: 10,
                    borderRadius: 5,
                    border: "1px dashed #18768f",
                  }}
                >
                  <div style={{ minWidth: "100%" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop: 5,
                        margiBottom: 5,
                      }}
                    >
                      Order Details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="cn-key">Total Products Price: </div>
                      <div style={{ fontWeight: "bold" }}>
                        {item.price_range
                          ? Math.round(price2 * this.state.totalPayableQuantity)
                          : this.state.totalPrice}{" "}
                        Tk
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="cn-key">
                        China Local shipping charge:{" "}
                      </div>
                      <div style={{ fontWeight: "bold" }}>0 Tk</div>
                    </div>
                    <div
                      style={{
                        border: "1px dotted #18768f",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="cn-key">Total: </div>
                      <div style={{ fontWeight: "bold" }}>
                        {item.price_range
                          ? price2 * this.state.totalPayableQuantity
                          : this.state.totalPrice}{" "}
                        Tk
                      </div>
                    </div>
                    <div
                      style={{
                        color: "red",
                        fontSize: 9,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      *China to Bangladesh customs & shipping charge will be
                      included later.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.warningMessage ? (
            <div
              class="alert alert-danger"
              role="alert"
              style={{
                color: "white",
                backgroundColor: "orange",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              Please fill the missing information first
            </div>
          ) : (
            ""
          )}
          {!currentUser ? (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                color: "white",
                backgroundColor: "orange",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              You must login first
            </div>
          ) : (
            ""
          )}

          <div className="d-flex flex-row product-buttons shipping-info-mobile-view">
            {currentUser ? (
              <>
                <button
                  className="order-now-button"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.BuynowClicked(
                      price2,
                      price2 * this.state.totalPayableQuantity
                    )
                  }
                >
                  <i className="fa fa-shopping-bag" />
                  &nbsp;Order Now
                </button>
                <button
                  className="wishlist-btn"
                  style={{
                    padding: "9px 15px",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={async () => {
                    if (this.state.submit_loader) {
                      return;
                    }
                    this.setState({
                      submit_loader: true,
                    });
                    await this.addToCartClicked(
                      price2,
                      price2 * this.state.totalPayableQuantity
                    );
                    this.setState({
                      submit_loader: false,
                    });
                  }}
                >
                  <i className="fa fa-shopping-cart" />
                  <div
                    style={{ position: "absolute", left: "40px", top: "10px" }}
                  >
                    <CircleLoader
                      loading={this.state.submit_loader}
                      color="#18768f"
                      size={20}
                    />
                  </div>
                  &nbsp;
                  <span
                    style={{
                      color: this.state.submit_loader ? "white" : "#18768f",
                    }}
                  >
                    Add To Cart
                  </span>
                </button>

                <button
                  className="wishlist-btn"
                  onClick={() => addToWishlistClicked(item)}
                  style={{
                    padding: "7px 15px",
                  }}
                >
                  <i className="fa fa-heart" />
                  <span className="title-font">&nbsp;Add To WishList</span>
                </button>
              </>
            ) : (
              <a
                className="btn btn-solid"
                onClick={() =>
                  this.props.history.push("/pages/login", {
                    from: this.props.match.url,
                  })
                }
                style={{ padding: "10px 30px" }}
              >
                Login
              </a>
            )}
          </div>
          <div style={{ border: "1px dashed #dddddd", marginBottom: 10 }} />
          <div
            className="border-product"
            style={{
              borderRadius: "7px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              background: "rgb(255, 255, 255)",
              display: "inline-block",
              paddingLeft: 20,
              paddingRight: 20,
              marginBottom: 20,
              marginTop: 5,
              borderTop: "none",
            }}
          >
            <h6
              className="product-title"
              style={{ borderBottom: "1px dashed", paddingBottom: "5px" }}
            >
              Seller details
            </h6>
            <div>
              <i
                className="icofont-bank-alt"
                style={{ fontSize: 60, color: "#18768f" }}
              />
            </div>
            <div
              style={{
                marginTop: 0,
              }}
            >
              Seller Id: {item.shop_id}
            </div>
            <div style={{}}>
              Seller Name:{" "}
              <span style={{ fontWeight: "bold" }}>{item.shop_name} </span>
            </div>
            <div
              style={{
                marginBottom: 5,
              }}
            >
              Seller Score: {RatingStars2.length}
            </div>
            <div
              style={{
                display: "inline-block",
                textAlign: "center",
                color: "white",
                background: "#18768f",
                padding: "5px 10px",
                borderRadius: "7px",
                fontSize: "12px",
              }}
            >
              <a
                target="_blank"
                href={`/collection/${item.shop_id}?shop_name=${item.shop_name}`}
                style={{ color: "white" }}
              >
                View Store
              </a>
            </div>
          </div>

          <div className="border-product" style={{ paddingBottom: "30px" }}>
            <h6 className="product-title">share it</h6>
            <div className="product-icon">
              <ul className="product-social">
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://plus.google.com/discover" target="_blank">
                    <i className="fa fa-google-plus" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
              <button
                className="wishlist-btn"
                onClick={() => addToWishlistClicked(item)}
              >
                <i className="fa fa-heart" />
                <span className="title-font">Add To WishList</span>
              </button>
            </div>
          </div>
        </div>
        <div
          className="shipping-info-pc-view shipping-info-div"
          style={{
            position: "absolute",
            top: -70,
            zIndex: 1000,
            right: "-100%",
            padding: 20,
            paddingTop: 30,
            paddingBottom: 30,
            borderRadius: "7px",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            background: "rgb(255, 255, 255)",
          }}
        >
          <div
            className="local-courier-charge-div"
            style={{ marginBottom: 10, marginTop: 20, paddingRight: 0 }}
          >
            <div className="form-group shipping-method">
              <div
                style={{
                  textAlign: "center",

                  fontWeight: "bold",
                  padding: 12,
                  background: "beige",
                  color: "black",
                  fontSize: 12,
                }}
              >
                Know Your Customs & Shipping rate{" "}
                <span style={{ fontWeight: "bold" }}>(for 5kg+)</span>:
              </div>

              <select
                className="form-control custom-select"
                id="exampleFormControlSelect1"
                name="shipping"
                value={this.state.shipping}
                style={{ fontSize: 13 }}
                onChange={this.handleChange}
                onClick={() => {
                  this.props.getAllD2DRatesRedux("air", "china");
                }}
              >
                <option>Choose your product type</option>
                {d2dRates.map((rate) => (
                  <option value={`${rate.id}-${rate.eleven}`} key={rate.id}>
                    {rate.id} - {rate.eleven}Tk/Kg
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div
            className="local-courier-charge-div"
            style={{ marginBottom: 10, marginTop: 5, paddingRight: 0 }}
          >
            <div
              className="d-flex flex-row"
              style={{
                textAlign: "center",
                justifyContent: "center",
                fontWeight: "bold",
                padding: 12,
                background: "beige",
                color: "black",
                fontSize: 13,
              }}
            >
              From China To Bangladesh
            </div>
            <div
              className="d-flex flex-row cn-key"
              style={{
                textAlign: "left",
                justifyContent: "flex-start",
                padding: 7,
                border: "1px solid gainsboro",
                fontSize: 12,
              }}
            >
              Total Quantity:{" "}
              <span style={{ fontWeight: "bold", marginLeft: 7, fontSize: 12 }}>
                {this.state.totalPayableQuantity} pc{" "}
              </span>
            </div>
            <div
              className="d-flex flex-row cn-key"
              style={{
                textAlign: "left",
                justifyContent: "flex-start",
                padding: 7,
                border: "1px solid gainsboro",
                fontSize: 12,
              }}
            >
              Approx Weight:{" "}
              <span style={{ fontWeight: "bold", marginLeft: 7, fontSize: 12 }}>
                {parseFloat(
                  item.item_weight * this.state.totalPayableQuantity
                ).toFixed(2)}
                kg{"   "}
              </span>
              <div style={{ position: "relative" }}>
                <img
                  className="myDIV"
                  src={Excl}
                  style={{
                    height: 18,
                    width: 18,
                    marginLeft: 6,
                    marginTop: 0,
                    cursor: "pointer",
                  }}
                />
                <div
                  className="hide"
                  style={{
                    position: "absolute",
                    padding: "10px",
                    borderRadius: 5,
                    border: "1px solid #18768f",
                    maxWidth: 120,
                    minWidth: 120,
                    zIndex: 100,
                    background: "beige",
                    fontSize: 11,
                    left: "30px",
                    top: "0px",
                  }}
                >
                  {item.item_weight && item.item_weight !== 0
                    ? "এটি পণ্যের সম্ভাব্য ওজন। প্রকৃত ওজন পণ্য বাংলাদেশে আসার পর পুনরায় নির্বাচন করা হবে"
                    : "প্রকৃত ওজন পণ্য বাংলাদেশে আসার পর নির্বাচন করা হবে"}
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-row cn-key"
              style={{
                textAlign: "left",
                justifyContent: "flex-start",
                padding: 7,
                border: "1px solid gainsboro",
                fontSize: 12,
              }}
            >
              Product Type:{" "}
              <span style={{ fontWeight: "bold", marginLeft: 7, fontSize: 12 }}>
                {this.state.productType}{" "}
              </span>
            </div>
            <div
              className="d-flex flex-row cn-key"
              style={{
                textAlign: "left",
                justifyContent: "flex-start",
                padding: 7,
                border: "1px solid gainsboro",
                fontSize: 12,
              }}
            >
              Customs & Shipping Rate:
              {this.state.shippingRate == 0 ? (
                <span
                  style={{
                    marginLeft: 7,
                    fontSize: 12,
                  }}
                >
                  Please choose product type first
                </span>
              ) : (
                <span
                  style={{ fontWeight: "bold", marginLeft: 7, fontSize: 12 }}
                >
                  {" "}
                  Tk {this.state.shippingRate}/kg
                </span>
              )}{" "}
              {d2dRate && (
                <div style={{ position: "relative" }}>
                  <img
                    className="myDIV2"
                    src={Excl}
                    style={{
                      height: 18,
                      width: 18,
                      marginLeft: 6,
                      marginTop: -4,
                      cursor: "pointer",
                    }}
                  />
                  <div
                    className="hide shipping-rate-hidden"
                    style={{
                      position: "absolute",
                      padding: "10px",
                      borderRadius: 5,
                      border: "1px solid #18768f",
                      maxWidth: 270,
                      minWidth: 270,
                      zIndex: 100,
                      background: "beige",
                      fontSize: 11,
                      left: "-160px",
                      top: "20px",
                    }}
                  >
                    উল্লেখ্য শিপিং রেট ৫কেজির উপরের অর্ডারের জন্য। <br />
                    ৩০১গ্রাম -৫কেজির জন্য শিপিং রেট - {d2dRate.ten} tk/kg <br />
                    ৩০০ গ্রামের নিচের পার্সেলের শিপিং রেট - {d2dRate.parcel} tk
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                padding: 7,
                border: "1px solid gainsboro",
                paddingBottom: 23,
              }}
            >
              <div
                className="d-flex flex-row cn-key"
                style={{
                  textAlign: "left",
                  justifyContent: "flex-start",
                  fontSize: 12,
                }}
              >
                Total Cost:{" "}
                <span
                  style={{ fontWeight: "bold", marginLeft: 7, fontSize: 12 }}
                >
                  Tk{" "}
                  {item.price_range
                    ? price2 * this.state.totalPayableQuantity
                    : this.state.totalPrice}
                </span>{" "}
              </div>
              <div
                style={{ float: "right", fontSize: 10, fontWeight: "bold" }}
                className="china-local"
              >
                {" "}
                + china local courier charge + customs & shipping charge
              </div>
            </div>
          </div>
          <div className="local-courier-charge-div" style={{ paddingRight: 0 }}>
            <div
              className="d-flex flex-row order-details-container-box"
              style={{
                background: "aliceblue",
                padding: 10,
                borderRadius: 5,
                border: "1px dashed #18768f",
              }}
            >
              <div style={{ minWidth: "100%" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: 5,
                    margiBottom: 5,
                    fontSize: 13,
                  }}
                >
                  Order Details
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="cn-key" style={{ fontSize: 12 }}>
                    Total Products Price:{" "}
                  </div>
                  <div style={{ fontWeight: "bold", fontSize: 13 }}>
                    {item.price_range
                      ? price2 * this.state.totalPayableQuantity
                      : this.state.totalPrice}{" "}
                    Tk
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="cn-key" style={{ fontSize: 12 }}>
                    China Local shipping charge:{" "}
                  </div>
                  <div style={{ fontWeight: "bold", fontSize: 13 }}>0 Tk</div>
                </div>
                <div
                  style={{
                    border: "1px dotted #18768f",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="cn-key">Total: </div>
                  <div style={{ fontWeight: "bold", fontSize: 13 }}>
                    {item.price_range
                      ? price2 * this.state.totalPayableQuantity
                      : this.state.totalPrice}{" "}
                    Tk
                  </div>
                </div>
                <div
                  style={{
                    color: "red",
                    fontSize: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  *China to Bangladesh customs & shipping charge will be
                  included later.
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-row product-buttons shipping-info-pc-view-2"
            style={{ marginTop: 30, justifyContent: "center" }}
          >
            {currentUser ? (
              <>
                <a
                  className="order-now-button"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.BuynowClicked(
                      price2,
                      price2 * this.state.totalPayableQuantity
                    )
                  }
                >
                  <i className="fa fa-shopping-bag" />
                  &nbsp;Order Now
                </a>
                <a
                  className="wishlist-btn"
                  style={{
                    padding: "9px 15px",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  onClick={async () => {
                    if (this.state.submit_loader) {
                      return;
                    }
                    this.setState({
                      submit_loader: true,
                    });
                    await this.addToCartClicked(
                      price2,
                      price2 * this.state.totalPayableQuantity
                    );
                    this.setState({
                      submit_loader: false,
                    });
                  }}
                >
                  <i className="fa fa-shopping-cart" />
                  <div
                    style={{ position: "absolute", left: "40px", top: "10px" }}
                  >
                    <CircleLoader
                      loading={this.state.submit_loader}
                      color="#18768f"
                      size={20}
                    />
                  </div>
                  &nbsp;
                  <span
                    style={{
                      color: this.state.submit_loader ? "white" : "#18768f",
                    }}
                  >
                    Add To Cart
                  </span>
                </a>
              </>
            ) : (
              <a
                className="btn btn-solid"
                style={{ padding: "10px 30px" }}
                onClick={() =>
                  this.props.history.push("/pages/login", {
                    from: this.props.match.url,
                  })
                }
              >
                Login
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser.displayName,
  d2dRates: state.bookingRequests.d2dRates,
  currency: state.user.currency,
});
export default connect(
  mapStateToProps,
  { addToPendingOrdersRedux, getAllD2DRatesRedux }
)(DetailsWithPriceApi);
