import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addToOrdersApiRedux,
  removeItemFromCartRedux,
} from "../../actions/index";
import "./placeOrder.css";
import PaymentModal from "./paymentModal";
import { toast } from "react-toastify";
import DiscountModal from "./discountModal";
import { CircleLoader } from "react-spinners";
import Success from "./success.png";
import {
  getSingleCoupon,
  updateUser,
  getAllPartials,
} from "../../firebase/firebase.utils";
class PlaceOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      pendingOrders: [],
      toggleModal: true,
      toggleModal2: true,
      additionalNotes: "",
      submit_loader: false,
      total: 0,
      payableTotal: 0,
      offer: 0,
      payNowPercent: 0,
      couponCode: "",
      coupon: null,
      orderPlaced: false,
      partials: [],
    };
  }

  componentDidMount = async () => {
    const { currentUser, pendingOrders, currency } = this.props;
    if (pendingOrders.length == 0) {
      this.props.history.push("/dashboard/pages/cart");
    } else {
      let partials = await getAllPartials();
      this.setState({ partials: partials });
    }
  };

  getSingleShopTotal = (shop) => {
    const { currentUser, pendingOrders, currency } = this.props;
    let total = 0;
    shop.items.map((item) => {
      item.skus.map((sku) => {
        total += parseInt(parseFloat(sku.price) * parseInt(sku.totalQuantity));
      });
    });
    return total;
  };

  startToggleModal = async (
    pendingOrders,
    total,
    payableTotal,
    offer,
    payNowPercent
  ) => {
    if (pendingOrders == null) {
      this.setState({
        toggleModal: !this.state.toggleModal,
        pendingOrders: [],
        total: 0,
        payableTotal: 0,
        offer: 0,
        payNowPercent: 0,
      });
      this.props.history.push("/pages/dashboard/buy-and-ship-for-me/my-orders");
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        pendingOrders,
        total,
        payableTotal,
        offer,
        payNowPercent,
      });
    }
  };
  startToggleModal2 = async (pendingOrders) => {
    console.log(pendingOrders);
    if (pendingOrders == null || pendingOrders == "proceed") {
      this.setState({
        toggleModal2: !this.state.toggleModal2,
        pendingOrders: [],
      });
      if (pendingOrders == null) {
        this.props.history.push(
          "/pages/dashboard/buy-and-ship-for-me/my-orders"
        );
      }
    } else {
      this.setState({
        toggleModal2: !this.state.toggleModal2,
        pendingOrders,
      });
    }
  };

  getTotalQuantityShop = (shop) => {
    let total = 0;
    shop.items.map((item) => {
      item.skus.map((sku) => {
        total += parseInt(sku.totalQuantity);
      });
    });
    return total;
  };

  handleSubmit = async (couponAmount) => {
    const { currentUser, currency } = this.props;
    let { pendingOrders } = this.props;
    console.log(pendingOrders);

    let orderId;
    pendingOrders = pendingOrders.map((shop) => {
      if (shop.orderId) {
        orderId = shop.orderId;
      } else {
        orderId = Math.floor(Math.random() * Date.now());
      }
      const date = new Date();
      const orderedDate = date.toLocaleDateString("en-GB");
      const orderedTime = date.toLocaleTimeString("en-US");
      let orderTotal = 0;
      shop.items.map((item) => {
        item.skus.map((sku) => {
          orderTotal += parseInt(
            parseFloat(sku.price) * parseFloat(sku.totalQuantity)
          );
        });
      });

      return {
        ...shop,
        orderId,
        time: date.getTime(),
        orderedDate,
        orderedTime,
        orderStatus: "pending",
        actualTotal: parseInt(orderTotal),
        actualTotalAfterCoupon:
          parseInt(orderTotal) -
          parseInt(couponAmount ? couponAmount / pendingOrders.length : 0),
        orderTotal:
          parseInt(orderTotal) -
          parseInt(couponAmount ? couponAmount / pendingOrders.length : 0),
        couponUsed: this.state.coupon,
        couponAmount: couponAmount ? couponAmount / pendingOrders.length : 0,
        paymentStatus: "purchaseLater",
        additionalNotes: this.state.additionalNotes,
        shipmentStatusScore: 1,
        websiteName: "1688.com",
        currency: currency.taka,
      };
    });
    if (this.state.partials && this.state.partials.length > 0) {
      this.startToggleModal2(pendingOrders);
    } else {
      this.startToggleModal(pendingOrders);
    }

    await this.props.addToOrdersApiRedux(currentUser, pendingOrders);

    for (let i = 0; i < pendingOrders.length; i++) {
      const shop = pendingOrders[i];
      for (let i = 0; i < shop.items.length; i++) {
        const element = shop.items[i];
        await this.props.removeItemFromCartRedux(currentUser, {
          ...element,
          shopId: shop.shopId,
        });
      }
    }
    if (this.state.coupon) {
      let usedCoupons = [{ ...this.state.coupon, used: 1 }];
      if (currentUser.usedCoupons && currentUser.usedCoupons.length > 0) {
        if (
          currentUser.usedCoupons.find(
            (coup) => coup.id == this.state.coupon.id
          )
        ) {
          usedCoupons = currentUser.usedCoupons.map((coup) => {
            if (coup.id === this.state.coupon.id) {
              return { ...coup, used: parseInt(coup.used) + 1 };
            } else {
              return coup;
            }
          });
        } else {
          usedCoupons = [
            ...currentUser.usedCoupons,
            { ...this.state.coupon, used: 1 },
          ];
        }
      }
      await updateUser({ ...currentUser, usedCoupons });
    }
    // toast.success("Order is placed!");
    this.setState({ orderPlaced: true });
  };

  addCoupon = async (coupon) => {
    toast.success("Coupon added successfully");
    this.setState({
      coupon,
      couponCode: "",
    });
  };

  handleAddCoupon = async () => {
    const { currentUser, pendingOrders, currency } = this.props;
    let totalAmount = 0;

    pendingOrders.map((shop) => {
      shop.items.map((item) => {
        item.skus.map((sku) => {
          totalAmount += parseInt(
            parseFloat(sku.price) * parseFloat(sku.totalQuantity)
          );
        });
      });
    });

    if (this.state.couponCode == "") {
      alert("Please add a coupon code first.");
      return;
    }
    let coupon = await getSingleCoupon(this.state.couponCode);
    if (coupon) {
      if (coupon.minimumOrder <= totalAmount) {
        let today = new Date().getTime();
        let expirationDate = new Date(coupon.expirationDate).getTime();

        console.log(today);
        console.log(expirationDate);
        if (expirationDate >= today) {
          if (currentUser.usedCoupons && currentUser.usedCoupons.length > 0) {
            let findCoupon = currentUser.usedCoupons.find(
              (coupon1) => coupon1.id == coupon.id
            );
            if (findCoupon) {
              if (findCoupon.used < coupon.usageLimit) {
                this.addCoupon(coupon);
              } else {
                alert(
                  `You've already used this coupon more than the maximum usage limit: ${
                    coupon.usageLimit
                  }`
                );
              }
            } else {
              this.addCoupon(coupon);
            }
          } else {
            this.addCoupon(coupon);
          }
        } else {
          alert("Sorry this coupon has expired!.");
        }
      } else {
        alert(`your minimum order amount must be ${coupon.minimumOrder}Tk`);
      }
      console.log(coupon);
    } else {
      alert("No coupon available with this coupon code. try another");
    }
  };

  render() {
    const { currentUser, pendingOrders, currency } = this.props;
    console.log(currentUser);
    console.log(pendingOrders);

    let totalAmount = 0;
    let goodsCategory = 0;
    let totalQuantity = 0;
    pendingOrders.map((shop) => {
      goodsCategory += shop.items.length;
      shop.items.map((item) => {
        item.skus.map((sku) => {
          totalAmount += parseInt(
            parseFloat(sku.price) * parseFloat(sku.totalQuantity)
          );
          totalQuantity += parseInt(sku.totalQuantity);
        });
      });
    });
    let couponAmount = 0;
    if (this.state.coupon) {
      if (this.state.coupon.discountType == "percentage") {
        couponAmount = (totalAmount * this.state.coupon.discountAmount) / 100;
        if (couponAmount > this.state.coupon.maximumDiscount) {
          couponAmount = this.state.coupon.maximumDiscount;
        }
      } else {
        couponAmount = this.state.coupon.discountAmount;
      }
    }
    let defaultShipping = null;
    if (
      currentUser &&
      currentUser.addressBook &&
      currentUser.addressBook.length > 0
    ) {
      defaultShipping = currentUser.addressBook.find(
        (address) => address.defaultShipping
      );
    }
    return (
      <div className="parent-main-container-cart">
        <Breadcrumb title={"Dashboard"} />
        <DiscountModal
          toggleModal2={this.state.toggleModal2}
          startToggleModal2={this.startToggleModal2}
          startToggleModal={this.startToggleModal}
          pendingOrders={this.state.pendingOrders}
          couponAmount={couponAmount}
          partials={this.state.partials}
        />
        <PaymentModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          pendingOrders={this.state.pendingOrders}
          total={this.state.total}
          payableTotal={this.state.payableTotal}
          offer={this.state.offer}
          payNowPercent={this.state.payNowPercent}
          couponAmount={couponAmount}
        />
        {/*Dashboard section*/}

        <>
          <section
            className="section-b-space main-container-cart"
            style={{ backgroundColor: "#f8f8f8" }}
          >
            <div className="container" style={{ padding: 10 }}>
              <div
                className="row"
                style={{
                  padding: 10,
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <div className="right-detail-column">
                  <div
                    className="row search-bar-row"
                    style={{
                      padding: "20px",
                      paddingRight: "0px",
                      paddingBottom: 10,
                      justifyContent: "flex-start",
                    }}
                  >
                    <form className="form-inline subscribe-form">
                      <div className="form-group mx-sm-3">
                        <div className="purchase-title">Place Order Now</div>
                      </div>
                    </form>
                  </div>

                  <div className="row title-header-2">
                    <div className="col payment-info-header">
                      Please keep that in mind that total payable of this order
                      is{" "}
                      <span style={{ color: "darkorange" }}>
                        not including the shipping charge (CN to BD)
                      </span>{" "}
                      and will be included later after the products are received
                      in our local warehouse.Thank you.
                    </div>
                  </div>
                  <div className="row user-info">
                    <div style={{ color: "gray", fontSize: 12 }}>
                      shipping address:{" "}
                    </div>
                    {defaultShipping ? (
                      <div style={{ paddingLeft: 20 }}>
                        <div style={{ fontSize: 12 }}>
                          <i
                            className="icofont-user-male"
                            style={{ color: "gainsboro" }}
                          />{" "}
                          {defaultShipping.name}
                        </div>
                        <div style={{ fontSize: 12 }}>
                          <i
                            className="icofont-location-pin"
                            style={{ color: "gainsboro" }}
                          />{" "}
                          {defaultShipping.address},{" "}
                        </div>
                        <div style={{ fontSize: 12 }}>
                          <>
                            <i
                              className="icofont-telephone"
                              style={{ color: "gainsboro" }}
                            />{" "}
                            {defaultShipping.district}
                          </>
                          <>
                            <i
                              className="icofont-smart-phone"
                              style={{ color: "gainsboro", marginLeft: 10 }}
                            />{" "}
                            {defaultShipping.mobileNo}
                          </>
                        </div>
                        <div style={{ fontSize: 12 }}>
                          <i
                            className="icofont-ui-email"
                            style={{ color: "gainsboro" }}
                          />{" "}
                          {currentUser.email}
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            marginTop: 3,
                            color: "#f48110",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.props.history.push(
                              "/pages/dashboard/user/information",
                              { from: "place-order" }
                            );
                          }}
                        >
                          change address
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: 12,
                          marginLeft: 10,
                          color: "#f48110",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          this.props.history.push(
                            "/pages/dashboard/user/information",
                            { from: "place-order" }
                          );
                        }}
                      >
                        +Add address
                      </div>
                    )}
                  </div>
                  {this.state.coupon ? (
                    <div className="row user-info">
                      <div
                        style={{
                          fontSize: 14,

                          fontWeight: "bold",
                        }}
                      >
                        Coupon code applied successfully!{" "}
                      </div>
                      <div style={{ color: "#18768f" }}>
                        Coupon name:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          ({this.state.coupon.name})
                        </span>{" "}
                        <span
                          style={{
                            marginLeft: 10,
                            textDecoration: "underline",
                            color: "#f48110",
                          }}
                          onClick={() => {
                            this.setState({
                              coupon: null,
                            });
                          }}
                        >
                          {" "}
                          change
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="row user-info">
                      <div
                        style={{
                          color: "#18768f",
                          fontSize: 14,
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Have coupon code?
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          style={{
                            borderRadius: 5,
                            maxWidth: "280px",
                            textAlign: "start",
                            color: "#18768f",
                          }}
                          type="text"
                          name="couponCode"
                          value={this.state.couponCode}
                          onChange={(event) => {
                            this.setState({
                              couponCode: event.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Enter Coupon code"
                        />
                        <div
                          style={{
                            padding: "6px 16px",
                            color: "white",
                            background: "#18768f",
                            fontWeight: "bold",
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            marginLeft: -30,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.handleAddCoupon();
                          }}
                        >
                          Add
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    {pendingOrders.length > 0 &&
                      pendingOrders.map((shop, index) => (
                        <div style={{ marginTop: 20 }} key={index}>
                          <div className="row each-order-container">
                            <div className="row order-header-3">
                              <div style={{ fontSize: 11 }}>
                                Shop: &nbsp;{shop.shopName}
                                &nbsp;
                                <i
                                  className="icofont-bomb"
                                  style={{ color: "blue", fontSize: 17 }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{
                              backgroundColor: "white",
                              padding: "0px 5px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                display: "flex",
                                backgroundColor: "white",
                                padding: "20px 50px",
                                borderBottom: "3px dashed #ebebeb",
                              }}
                            >
                              <div style={{ color: "gray" }}>
                                Order {index + 1}:
                              </div>
                              <div style={{ maxWidth: "85%", marginLeft: 40 }}>
                                <div className="row products-box-2">
                                  <div className="row order-header-2">
                                    <div
                                      className="col-5"
                                      style={{
                                        fontWeight: "lighter",
                                        fontSize: 12,
                                      }}
                                    >
                                      Goods Information{" "}
                                    </div>
                                    <div className="col">
                                      <div
                                        className="row"
                                        style={{
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        <div
                                          className="col"
                                          style={{
                                            fontWeight: "lighter",
                                            fontSize: 12,
                                            marginLeft: -10,
                                          }}
                                        >
                                          Unit Price (taka)
                                        </div>
                                        <div
                                          className="col"
                                          style={{
                                            fontWeight: "lighter",
                                            fontSize: 12,
                                          }}
                                        >
                                          Quantity
                                        </div>
                                        <div
                                          className="col"
                                          style={{
                                            fontWeight: "lighter",
                                            fontSize: 12,
                                          }}
                                        >
                                          Total Amount
                                        </div>
                                        <div
                                          className="col"
                                          style={{
                                            fontWeight: "lighter",
                                            fontSize: 12,
                                          }}
                                        >
                                          CN local courier charge
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    {shop.items.map((item) => {
                                      return item.skus.map((sku, index) => (
                                        <div
                                          className="row"
                                          style={{
                                            padding: 5,
                                            borderTop: "1px solid gainsboro",
                                            borderRight: "1px solid gainsboro",
                                            paddingBottom: 25,
                                          }}
                                          key={index}
                                        >
                                          <div
                                            className="col-1"
                                            style={{ margin: "auto" }}
                                          >
                                            <div
                                              style={{
                                                height: 60,
                                                width: 60,
                                                overflow: "hidden",
                                                backgroundImage: `url(${
                                                  sku.image
                                                    ? sku.image
                                                    : item.picture
                                                })`,
                                                backgroundPosition: "center",
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                border: "1px solid gainsboro",
                                                borderRadius: 5,
                                              }}
                                            />
                                          </div>
                                          <div className="col-4">
                                            <div
                                              style={{
                                                color: "#4a4a4a",
                                                fontSize: 12,
                                              }}
                                            >
                                              {item.name}
                                            </div>
                                            <div
                                              style={{
                                                color: "gray",
                                                fontSize: 12,
                                                marginTop: 10,
                                              }}
                                            >
                                              <span style={{ color: "#222" }}>
                                                Product Type:
                                              </span>{" "}
                                              {item.productType}
                                            </div>
                                            {item.propertyNames &&
                                              item.propertyNames.length > 0 && (
                                                <div
                                                  style={{
                                                    color: "gray",
                                                    fontSize: 12,
                                                  }}
                                                >
                                                  <span
                                                    style={{ color: "#222" }}
                                                  >
                                                    {item.propertyNames[0]}:
                                                  </span>{" "}
                                                  {sku.color}, <br />
                                                  {sku.size ? (
                                                    <>
                                                      <span
                                                        style={{
                                                          color: "#222",
                                                        }}
                                                      >
                                                        {item.propertyNames[1]}:
                                                      </span>{" "}
                                                      {sku.size}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                          <div
                                            className="col"
                                            style={{ margin: "auto" }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "auto",
                                                }}
                                              >
                                                {parseInt(
                                                  parseFloat(sku.price)
                                                )}
                                                Tk
                                              </div>
                                              <div
                                                style={{
                                                  margin: "auto",
                                                }}
                                              >
                                                {sku.totalQuantity}
                                              </div>
                                              <div
                                                style={{
                                                  color: "black",
                                                  fontWeight: "bold",
                                                  margin: "auto",
                                                }}
                                              >
                                                {parseInt(
                                                  parseInt(sku.totalQuantity) *
                                                    parseFloat(sku.price)
                                                )}
                                                Tk
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ));
                                    })}
                                  </div>
                                  <div
                                    className="col"
                                    style={{
                                      paddingTop: 5,
                                      borderTop: "1px solid gainsboro",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}
                                    >
                                      <div
                                        className="row"
                                        style={{ marginTop: 30 }}
                                      >
                                        <div className="col">
                                          <div
                                            style={{
                                              fontSize: 13,
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            0
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{
                                      padding: 20,
                                      justifyContent: "flex-end",
                                      borderTop: "1px solid gainsboro",
                                      width: "100%",
                                      margin: 0,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          textAlign: "end",
                                          fontSize: 13,
                                          fontWeight: "lighter",
                                          marginBottom: 10,
                                        }}
                                      >
                                        Total Amount of goods:{" "}
                                        <span
                                          style={{
                                            color: "#ed6b1d",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {this.getSingleShopTotal(shop)}
                                        </span>{" "}
                                        taka
                                      </div>
                                      <div
                                        style={{
                                          textAlign: "end",
                                          fontSize: 13,
                                          fontWeight: "lighter",
                                        }}
                                      >
                                        Customs & Shipping charge:{" "}
                                        <span
                                          style={{
                                            color: "#ed6b1d",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          0.00
                                        </span>{" "}
                                        taka{" "}
                                      </div>
                                      <div
                                        style={{
                                          textAlign: "start",
                                          fontSize: 11,
                                          fontWeight: "lighter",
                                          color: "red",
                                        }}
                                      >
                                        ***China to Bangladesh customs and
                                        shipping charge will be included later.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div
                    className="d-flex flex-row"
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 30,
                      backgroundColor: "#eaf5ff",
                      border: "1px solid rgb(246 241 194)",
                      marginRight: "-15px",
                      marginLeft: "-15px",
                    }}
                  >
                    <div>
                      Goods category:{goodsCategory} kinds <br />
                      Total quantity:{totalQuantity} pieces <br />
                      <div
                        style={{
                          color: "red",
                          float: "right",
                          fontSize: 11,
                          textAlign: "left",
                          justifyContent: "flex-start",
                          marginTop: 20,
                        }}
                      >
                        {" "}
                        ***China to Bangladesh customs and shipping charge will
                        be included later.
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ fontSize: 13 }}>
                        Total amount of goods:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {totalAmount}
                        </span>
                        &nbsp;taka
                      </div>
                      <div style={{ fontSize: 13 }}>
                        Customs & Shipping charge:{" "}
                        <span style={{ fontWeight: "bold" }}>0.00</span>
                        &nbsp;taka
                      </div>
                      {this.state.coupon && (
                        <div style={{ fontSize: 13 }}>
                          Coupon Applied{" "}
                          <span
                            style={{ color: "#18768f", fontWeight: "bold" }}
                          >
                            ({this.state.coupon.name})
                          </span>
                          :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            -{couponAmount}
                          </span>
                          &nbsp;taka
                        </div>
                      )}
                      <div
                        style={{
                          fontWeight: "bold",
                          marginTop: 5,
                          fontSize: 13,
                        }}
                      >
                        Total Payable ( excluding shipping charge ):{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#f54c3c",
                            fontSize: 16,
                          }}
                        >
                          {totalAmount - couponAmount}
                        </span>
                        &nbsp;taka
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="booking-input-box">
                      <label
                        style={{
                          color: "black",
                          marginBottom: 5,
                          fontWeight: "bold",
                          fontSize: 17,
                        }}
                      >
                        {" "}
                        Additional Instruction (Optional)
                      </label>
                      <textarea
                        style={{
                          borderRadius: 5,
                          height: 120,
                          maxWidth: 400,
                          textAlign: "start",
                        }}
                        type="text"
                        name="additionalNotes"
                        value={this.state.additionalNotes}
                        onChange={(event) => {
                          this.setState({
                            additionalNotes: event.target.value,
                          });
                        }}
                        className="form-control"
                        placeholder="Enter any additional notes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ padding: "10px 20px" }}
                className="sticky-settlement-container"
              >
                <div className="d-flex flex-row settlement-container">
                  <div
                    style={{
                      fontSize: 12,
                      marginTop: 3,
                      padding: "20px 10px",
                    }}
                  >
                    Return to purchase order
                  </div>

                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        padding: "20px 20px",
                      }}
                    >
                      Total payable(excluding shipping charge):{" "}
                      <span
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                          color: "#f54c3c",
                        }}
                      >
                        {totalAmount - couponAmount} Taka
                      </span>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {this.state.submit_loader ? (
                        <button
                          type="submit"
                          className="btn btn-solid"
                          style={{
                            fontSize: 8,
                            paddingLeft: 70,
                            paddingRight: 70,
                            minHeight: "100%",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: 15,
                              marginRight: 10,
                            }}
                          >
                            <CircleLoader
                              loading={this.state.submit_loader}
                              color="white"
                              size={25}
                            />
                          </div>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-solid"
                          style={{
                            fontSize: 8,
                            padding: 5,
                            paddingLeft: 22,
                            paddingRight: 22,
                            minHeight: "100%",
                          }}
                          onClick={async () => {
                            if (this.state.orderPlaced) {
                              this.props.history.push(
                                "/pages/dashboard/buy-and-ship-for-me/my-orders"
                              );
                              return;
                            }
                            if (defaultShipping) {
                              this.setState({
                                submit_loader: true,
                              });
                              await this.handleSubmit(couponAmount);
                              this.setState({
                                submit_loader: false,
                              });
                            } else {
                              alert(
                                "Please choose your shipping address above."
                              );
                            }
                          }}
                        >
                          {this.state.orderPlaced
                            ? "View Orders"
                            : "Place Order"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* mobile view  */}
          <section
            className="section-b-space mobile-view"
            style={{ backgroundColor: "#f8f8f8" }}
          >
            <div className="container">
              <div className="row">
                <div
                  className="right-detail-column"
                  style={{ minWidth: "100%" }}
                >
                  <div className="user-info">
                    <div
                      style={{
                        color: "gray",
                        fontSize: 10,
                        fontWeight: "bold",
                      }}
                    >
                      shipping address:{" "}
                    </div>
                    {defaultShipping ? (
                      <div>
                        <div style={{ fontSize: 10 }}>
                          <i
                            className="icofont-user-male"
                            style={{ color: "gainsboro" }}
                          />{" "}
                          {defaultShipping.name}
                        </div>
                        <div style={{ fontSize: 10 }}>
                          <i
                            className="icofont-location-pin"
                            style={{ color: "gainsboro" }}
                          />{" "}
                          {defaultShipping.address}
                        </div>
                        <div style={{ fontSize: 10 }}>
                          <>
                            <i
                              className="icofont-telephone"
                              style={{ color: "gainsboro" }}
                            />{" "}
                            {defaultShipping.district}
                          </>
                          <>
                            <i
                              className="icofont-smart-phone"
                              style={{ color: "gainsboro", marginLeft: 10 }}
                            />{" "}
                            {defaultShipping.mobileNo} {currentUser.email}
                          </>
                        </div>
                        <div style={{ fontSize: 10 }}>
                          <>
                            <i
                              className="icofont-ui-email"
                              style={{ color: "gainsboro" }}
                            />
                            {currentUser.email}
                          </>
                        </div>
                        <div
                          style={{
                            fontSize: 10,
                            marginTop: 3,
                            color: "#f48110",
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            this.props.history.push(
                              "/pages/dashboard/user/information",
                              { from: "place-order" }
                            );
                          }}
                        >
                          change address
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: 10,
                          marginLeft: 10,
                          color: "#f48110",
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          this.props.history.push(
                            "/pages/dashboard/user/information",
                            { from: "place-order" }
                          );
                        }}
                      >
                        +Add address
                      </div>
                    )}
                  </div>

                  {this.state.coupon ? (
                    <div className="user-info">
                      <div
                        style={{
                          fontSize: 12,

                          fontWeight: "bold",
                        }}
                      >
                        Coupon code applied successfully!{" "}
                      </div>
                      <div style={{ color: "#18768f", fontSize: 13 }}>
                        Coupon name:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          ({this.state.coupon.name})
                        </span>{" "}
                        <span
                          style={{
                            marginLeft: 5,
                            textDecoration: "underline",
                            color: "#f48110",
                            fontSize: 11,
                          }}
                          onClick={() => {
                            this.setState({
                              coupon: null,
                            });
                          }}
                        >
                          {" "}
                          change
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="user-info">
                      <div
                        style={{
                          color: "#18768f",
                          fontSize: 12,
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Have coupon code?
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          style={{
                            borderRadius: 5,

                            textAlign: "start",
                            color: "#18768f",
                            fontSize: 12,
                          }}
                          type="text"
                          name="couponCode"
                          value={this.state.couponCode}
                          onChange={(event) => {
                            this.setState({
                              couponCode: event.target.value,
                            });
                          }}
                          className="form-control"
                          placeholder="Enter Coupon code"
                        />
                        <div
                          style={{
                            padding: "6px 16px",
                            color: "white",
                            background: "#18768f",
                            fontWeight: "bold",
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            marginLeft: -30,
                            cursor: "pointer",
                            fontSize: 12,
                          }}
                          onClick={() => {
                            this.handleAddCoupon();
                          }}
                        >
                          Add
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="user-info" style={{ marginTop: 20 }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ fontSize: 11 }}>
                        Total amount of goods:{" "}
                        <span style={{ fontWeight: "bold", float: "right" }}>
                          {totalAmount}Tk
                        </span>
                      </div>
                      <div style={{ fontSize: 11 }}>
                        Customs & Shipping charge:{" "}
                        <span style={{ fontWeight: "bold", float: "right" }}>
                          0.00Tk
                        </span>
                      </div>
                      {this.state.coupon && (
                        <div style={{ fontSize: 11 }}>
                          Coupon Applied{" "}
                          <span
                            style={{
                              color: "#18768f",
                              fontWeight: "bold",
                            }}
                          >
                            ({this.state.coupon.name})
                          </span>
                          :{" "}
                          <span style={{ fontWeight: "bold", float: "right" }}>
                            -{couponAmount}Tk
                          </span>
                          &nbsp;
                        </div>
                      )}
                      <div
                        style={{
                          fontWeight: "bold",
                          marginTop: 5,
                          fontSize: 11,
                        }}
                      >
                        Total Payable ( excluding shipping charge ):{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "#f54c3c",
                            fontSize: 13,
                            float: "right",
                          }}
                        >
                          {totalAmount - couponAmount}Tk
                        </span>
                      </div>
                      <div
                        style={{
                          float: "right",
                          fontSize: 8,
                          color: "red",
                          marginRight: -15,
                        }}
                      >
                        ***China to Bangladesh customs and shipping charge will
                        be included later.
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: 30 }}>
                    {pendingOrders.map((shop, index) => (
                      <div key={index}>
                        <div className="each-order-container">
                          <div className="order-header reduce-padding-bottom">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div className="shop-title">
                                order {index + 1}: &nbsp; &nbsp;
                                <i
                                  className="icofont-brand-alipay"
                                  style={{ color: "darkred" }}
                                />
                                &nbsp;{shop.shopName}
                                &nbsp;
                              </div>
                            </div>
                          </div>
                        </div>
                        {shop.items.map((item, index) => (
                          <div className="products-box" key={index}>
                            <div className="image-title-container">
                              <div className="product-image-title-row">
                                <div
                                  className="product-image-box"
                                  style={{
                                    backgroundImage: `url(${item.picture})`,
                                    border: "1px solid gainsboro",
                                    borderRadius: 5,
                                  }}
                                />
                              </div>
                              <div>
                                <div
                                  className="product-title-text"
                                  style={{
                                    fontWeight: "bold",
                                    color: "black",
                                    paddingBottom: 0,
                                    fontSize: 11,
                                  }}
                                >
                                  {item.name.slice(0, 70)}...
                                </div>
                                <span
                                  style={{
                                    color: "#333",
                                    marginLeft: 10,
                                    fontSize: 11,
                                  }}
                                >
                                  Product Type:{item.productType}
                                </span>
                              </div>
                            </div>
                            {item.skus.map((sku, index) => (
                              <div
                                className="product-variant-row-container"
                                key={index}
                                style={{
                                  background: "#f7f7f7",
                                  marginTop: 10,
                                }}
                              >
                                <div
                                  className="product-image-box2"
                                  style={{
                                    backgroundImage: `url(${
                                      sku.image ? sku.image : item.picture
                                    })`,
                                    border: "1px solid gainsboro",
                                    borderRadius: 5,
                                  }}
                                />
                                <div
                                  className="size-color-variant-row"
                                  style={{ paddingTop: 0 }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {item.propertyNames &&
                                      item.propertyNames.length > 0 && (
                                        <div
                                          style={{
                                            color: "#4c4c4c",
                                            fontSize: 9,
                                          }}
                                        >
                                          <span style={{ color: "#222" }}>
                                            {item.propertyNames[0]}:
                                          </span>{" "}
                                          {sku.color} <br />
                                          {sku.size ? (
                                            <>
                                              {" "}
                                              <span style={{ color: "#222" }}>
                                                {item.propertyNames[1]}:
                                              </span>{" "}
                                              {sku.size}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )}
                                    <div
                                      style={{
                                        color: "gray",
                                        fontWeight: "bold",
                                        fontSize: 9,
                                        marginTop: 5,
                                      }}
                                    >
                                      {parseInt(parseFloat(sku.price))}
                                      tk/qunatity
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 9,
                                        fontWeight: "bold",
                                        marginTop: 5,
                                      }}
                                    >
                                      Total:{" "}
                                      {parseInt(
                                        parseInt(sku.totalQuantity) *
                                          parseFloat(sku.price)
                                      )}{" "}
                                      Taka
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      fontSize: 12,
                                    }}
                                  >
                                    {sku.totalQuantity} pieces
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "white",
                            padding: "10px 20px",
                            borderBottom: "1px solid gainsboro",
                          }}
                        >
                          <div style={{ fontSize: 10, color: "gray" }}>
                            CN local courier charge
                          </div>
                          <div>
                            <span
                              style={{
                                fontSize: 10,
                              }}
                            >
                              Tk 0
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "white",
                            padding: "6px 20px",
                            borderBottom: "1px solid gainsboro",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              color: "darkgray",
                            }}
                          >
                            <p style={{ fontSize: 10, marginBottom: 5 }}>
                              {shop.items.length} kinds
                            </p>
                            <p style={{ fontSize: 10, marginBottom: 5 }}>
                              {" "}
                              {this.getTotalQuantityShop(shop)} pieces in total
                            </p>
                          </div>
                          <div style={{ marginTop: 5 }}>
                            <span
                              style={{
                                fontSize: 10,
                              }}
                            >
                              Tk {this.getSingleShopTotal(shop)}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            backgroundColor: "white",
                            padding: "13px 20px",
                          }}
                        >
                          <div style={{ fontSize: 10 }}>
                            Total (excluding shipping charge)
                          </div>
                          <div style={{ marginTop: -5 }}>
                            <span
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                              }}
                            >
                              Tk {this.getSingleShopTotal(shop)}
                            </span>
                          </div>
                        </div>{" "}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  margin: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 15,
                }}
              >
                <div className="booking-input-box">
                  <label
                    style={{
                      color: "black",
                      marginBottom: 5,
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {" "}
                    Additional Instruction (Optional)
                  </label>
                  <textarea
                    style={{
                      borderRadius: 5,
                      height: 120,
                      fontSize: 12,
                    }}
                    type="text"
                    name="additionalNotes"
                    value={this.state.additionalNotes}
                    onChange={(event) => {
                      this.setState({
                        additionalNotes: event.target.value,
                      });
                    }}
                    className="form-control"
                    placeholder="Enter any additional notes"
                  />
                </div>
              </div>
            </div>
            <div
              style={{ padding: "10px 0px", marginBottom: 40 }}
              className="sticky-settlement-container"
            >
              <div className="d-flex flex-row settlement-container">
                <div
                  style={{
                    padding: "15px 0px",
                    fontSize: 10,
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 20,
                  }}
                >
                  <div
                    style={{
                      fontSize: 9,
                      fontWeight: "bold",
                      color: "darkorange",
                    }}
                  >
                    {totalAmount - couponAmount} Taka
                  </div>
                  <div style={{ fontSize: 8, color: "darkgray" }}>
                    Total amount (excluding shipping charge)
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {this.state.submit_loader ? (
                    <button
                      type="submit"
                      className="btn btn-solid"
                      style={{
                        fontSize: 8,
                        padding: 5,
                        paddingLeft: 40,
                        paddingRight: 40,
                        minHeight: "100%",
                      }}
                    >
                      <div style={{ marginBottom: 12, marginRight: 5 }}>
                        <CircleLoader
                          loading={this.state.submit_loader}
                          color="white"
                          size={20}
                        />
                      </div>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-solid"
                      style={{ fontSize: 8, padding: 5, minHeight: "100%" }}
                      onClick={async () => {
                        if (this.state.orderPlaced) {
                          this.props.history.push(
                            "/pages/dashboard/buy-and-ship-for-me/my-orders"
                          );
                          return;
                        }
                        if (defaultShipping) {
                          this.setState({
                            submit_loader: true,
                          });
                          await this.handleSubmit(couponAmount);
                          this.setState({
                            submit_loader: false,
                          });
                        } else {
                          alert("Please choose your shipping address above.");
                        }
                      }}
                    >
                      {this.state.orderPlaced ? "View Orders" : "Place Order"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  pendingOrders: state.cartList.pendingOrders,
  currency: state.user.currency,
});

export default connect(
  mapStateToProps,
  { removeItemFromCartRedux, addToOrdersApiRedux }
)(PlaceOrder);
