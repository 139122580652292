import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../myOrders.css";
import {
  getAllOrdersApiRedux,
  getOrderTrackingResultRedux,
  uploadRefundApplyRedux,
} from "../../../../actions";
import { HashLoader } from "react-spinners";
import LogisticsModal from "./logisticsModal";
import PaymentModal from "./paymentModalOrder";
import Success from "./success.png";
import { toast } from "react-toastify";
class MyForwardingParcels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      pending: true,
      approved: false,
      reject: false,
      toggleLogisticsModal: true,
      order: null,
      pendingOrders: [],
      toggleModal: true,
      searchOrder: "",
      abroad: true,
      bangladesh: false,
      filterByCategory: "",
    };
  }

  componentDidMount = () => {
    const { currentUser } = this.props;
    const url = new URLSearchParams(window.location.search);
    const paymentStatus = url.get("paymentStatus");

    if (paymentStatus) {
      if (paymentStatus == "cancel") {
        toast.error("Your payment is cancelled!");
      } else if (paymentStatus == "failure") {
        toast.error("Payment failed. Please try again.");
      } else if (paymentStatus == "success") {
        toast.success("Your payment is successfull!");
      } else {
        toast.error(`Your payment is ${paymentStatus}`);
      }
      // for removing query params from the url upon reload
      // Remove query parameters from the URL
      const url = new URL(window.location);
      url.search = ""; // Clear the query parameters
      window.history.replaceState({}, document.title, url);
    }
    console.log(currentUser);
    if (currentUser.id) {
      this.props.getAllOrdersApiRedux(currentUser.id);
    }
  };
  componentWillReceiveProps = (nextProps) => {
    const { currentUser } = nextProps;
    if (currentUser.id && this.props.currentUser.id != currentUser.id) {
      this.props.getAllOrdersApiRedux(currentUser.id);
    }
  };

  getSingleShopTotal = (shop) => {
    const { currency } = this.props;
    let total = 0;
    shop.items.map((item) => {
      item.skus.map((sku) => {
        total += parseInt(parseFloat(sku.price) * parseInt(sku.totalQuantity));
      });
    });
    return total;
  };

  startToggleLogisticsModal = async (order) => {
    if (order == null) {
      this.setState({
        toggleLogisticsModal: !this.state.toggleLogisticsModal,
        order: null,
      });
    } else {
      this.setState({
        toggleLogisticsModal: !this.state.toggleLogisticsModal,
        order,
      });
    }
  };

  startToggleModal = async (pendingOrders) => {
    console.log(pendingOrders);
    if (pendingOrders == null) {
      this.setState({
        toggleModal: !this.state.toggleModal,
        pendingOrders: [],
      });
    } else {
      this.setState({
        toggleModal: !this.state.toggleModal,
        pendingOrders,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  getTotalPaid = (order) => {
    let totalPaid = 0;
    if (order.payments && order.payments.length > 0) {
      order.payments.map((paymnet) => {
        totalPaid += parseInt(paymnet.amount);
      });
    }
    return totalPaid;
  };

  getTotalDue = (order) => {
    let totalPaid = 0;
    let totalDue = 0;
    let totalBill = 0;
    if (order.payments && order.payments.length > 0) {
      order.payments.map((paymnet) => {
        totalPaid += parseInt(paymnet.amount);
      });
    }
    if (order.shippingChargeCustomer && order.shippingChargeCustomer != 0) {
      totalBill = order.orderTotal
        ? parseInt(order.orderTotal) +
          parseInt(order.shippingChargeCustomer) +
          (order.localShipping ? parseInt(order.localShipping) : 0) +
          (order.otherCost ? parseInt(order.otherCost) : 0) -
          (order.discount ? parseInt(order.discount) : 0)
        : this.getSingleShopTotal(order) +
          parseInt(order.shippingChargeCustomer) +
          (order.localShipping ? parseInt(order.localShipping) : 0) +
          (order.otherCost ? parseInt(order.otherCost) : 0) -
          (order.discount ? parseInt(order.discount) : 0);
    } else {
      totalBill = order.orderTotal
        ? order.orderTotal +
          (order.localShipping ? parseInt(order.localShipping) : 0) +
          (order.otherCost ? parseInt(order.otherCost) : 0) -
          (order.discount ? parseInt(order.discount) : 0)
        : this.getSingleShopTotal(order) +
          (order.localShipping ? parseInt(order.localShipping) : 0) +
          (order.otherCost ? parseInt(order.otherCost) : 0) -
          (order.discount ? parseInt(order.discount) : 0);
    }
    totalDue = parseInt(totalBill) - parseInt(totalPaid);
    return totalDue;
  };

  applyForRefund = async () => {
    if (this.state.reason && this.state.paymentMethod && this.state.bankInfo) {
      let refundObj = {
        reason: this.state.reason,
        paymentMethod: this.state.paymentMethod,
        bankInfo: this.state.bankInfo,
        refundId: new Date().getTime(),
        order: this.state.order,
      };
      await this.props.uploadRefundApplyRedux(refundObj);
      document.getElementById("refund-info-close").click();
      document.getElementById("success-info-open").click();
    } else {
      alert("Please fill all the information");
      return;
    }
  };
  render() {
    const { currentUser, orders, currency } = this.props;
    console.log(currentUser);

    let ordersAbroad = orders.filter(
      (order) =>
        order.orderStatus == "Received in Warehouse" ||
        order.orderStatus == "Ready for fly" ||
        order.orderStatus == "Bangladesh customs" ||
        order.orderStatus == "Bangladesh warehouse"
    );
    let ordersBangladesh = orders.filter(
      (order) => order.orderStatus == "Paicart warehouse"
    );

    let ordersToShow = [];
    if (this.state.abroad) {
      ordersToShow = ordersAbroad;
    } else {
      ordersToShow = ordersBangladesh;
    }

    console.log(ordersToShow);

    if (this.state.searchOrder) {
      ordersToShow = ordersToShow.filter((booking) =>
        (booking.trackingNo &&
          booking.trackingNo
            .toLowerCase()
            .includes(this.state.searchOrder.toLowerCase())) ||
        (booking.orderNumber &&
          booking.orderNumber
            .toLowerCase()
            .includes(this.state.searchOrder.toLowerCase())) ||
        (booking.orderedDate &&
          booking.orderedDate
            .toLowerCase()
            .includes(this.state.searchOrder.toLowerCase())) ||
        (booking.orderId &&
          booking.orderId
            .toString()
            .toLowerCase()
            .includes(this.state.searchOrder.toLowerCase())) ||
        booking.items.find((item) =>
          item.productType
            .toLowerCase()
            .includes(this.state.searchOrder.toLowerCase())
        )
          ? true
          : false
      );
    }

    if (this.state.filterByCategory) {
      if (
        this.state.filterByCategory == "Paid" ||
        this.state.filterByCategory == "Partially Paid" ||
        this.state.filterByCategory == "purchaseLater"
      ) {
        ordersToShow = ordersToShow.filter(
          (booking) => booking.paymentStatus == this.state.filterByCategory
        );
      } else if (this.state.filterByCategory == "Not Paid") {
        ordersToShow = ordersToShow.filter(
          (booking) =>
            booking.paymentStatus == "Not paid" || !booking.paymentStatus
        );
      } else {
        if (this.state.filterByCategory !== "Bangladesh customs") {
          ordersToShow = ordersToShow.filter(
            (booking) => booking.orderStatus == this.state.filterByCategory
          );
        } else {
          ordersToShow = ordersToShow.filter(
            (booking) =>
              booking.orderStatus == "Bangladesh customs" ||
              booking.orderStatus == "Bangladesh warehouse"
          );
        }
      }
    }
    return (
      <div>
        <Breadcrumb title={"Dashboard"} />
        <LogisticsModal
          toggleLogisticsModal={this.state.toggleLogisticsModal}
          startToggleLogisticsModal={this.startToggleLogisticsModal}
          booking={this.state.order}
        />
        <PaymentModal
          toggleModal={this.state.toggleModal}
          startToggleModal={this.startToggleModal}
          pendingOrders={this.state.pendingOrders}
          fromOldOrderList={true}
        />
        {/*Dashboard section*/}
        <section
          className="section-b-space main-container-cart"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col-3 left-list-column">
                <div className="left-list-container">
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Buy & Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-request`
                          );
                        }}
                      >
                        My Request Products
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-orders`
                          );
                        }}
                      >
                        My Orders
                      </p>
                      <p
                        className="each-option"
                        style={{ color: "#f54c3c" }}
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        style={{ color: "gray" }}
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-request`
                          );
                        }}
                      >
                        My Booking
                      </p>

                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Profile
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/information`
                          );
                        }}
                      >
                        Information
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/security`
                          );
                        }}
                      >
                        Security
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.abroad ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        abroad: true,
                        bangladesh: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Abroad to Bangladesh ({ordersAbroad.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.bangladesh ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        abroad: false,
                        bangladesh: true,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Bangladesh to you ({ordersBangladesh.length})
                  </div>
                </div>
                <div
                  className="row search-bar-row"
                  style={{
                    padding: "20px",
                    paddingRight: "0px",
                    justifyContent: "space-between",
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ maxWidth: "30%" }}>
                    <select
                      title="Filter by category"
                      required
                      name="filterByCategory"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.filterByCategory}
                    >
                      <option value="">Filter by category </option>
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Partially Paid">Partially Paid</option>
                      <option value="purchaseLater">Not Paid</option>

                      {this.state.abroad ? (
                        <>
                          <option value="Received in Warehouse">
                            Received in Warehouse (abroad)
                          </option>
                          <option value="Ready for fly">Ready for fly</option>
                          <option value="Bangladesh customs">
                            Bangladesh customs
                          </option>
                        </>
                      ) : (
                        <option value="Paicart warehouse">
                          Paicart warehouse
                        </option>
                      )}
                    </select>
                  </div>
                  <div style={{ maxWidth: "30%" }}>
                    <form className="form-inline subscribe-form">
                      <div className="form-group mx-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="searchOrder"
                          value={this.state.searchOrder}
                          onChange={this.handleChange}
                          placeholder="Search Order"
                          style={{
                            padding: 8,
                            width: 250,
                            borderColor: "gainsboro",
                            borderRadius: 5,
                            fontSize: 14,
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-solid"
                        style={{ padding: "7px 10px", marginLeft: -50 }}
                      >
                        <i
                          className="icofont-search"
                          style={{ fontWeight: "bold" }}
                        />
                      </button>
                    </form>
                  </div>
                </div>
                {/* when no order is found  */}
                {ordersToShow.length == 0 ? (
                  <div className="main-order-section">
                    <div className="col-sm-12">
                      <div>
                        <div
                          className="col-sm-12 empty-cart-cls text-center"
                          style={{ marginTop: 50 }}
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/empty-search.jpg`}
                            className="img-fluid mb-4"
                            alt=""
                          />
                          <h3 style={{ paddingBottom: 50 }}>
                            <strong>You don't have any Orders here!</strong>
                          </h3>
                        </div>
                      </div>
                      <div style={{ paddingBottom: 40, textAlign: "center" }}>
                        <Link to="/">
                          <button
                            className="btn btn-solid"
                            style={{
                              padding: 6,
                              fontSize: 10,
                              borderRadius: 5,
                            }}
                          >
                            Continue Shopping
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {" "}
                    <div className="row title-header">
                      <div className="col-4">Goods</div>
                      <div className="col">Unit Price (taka)</div>
                      <div className="col">Quantity</div>
                      <div className="col">Total Amount</div>
                      <div className="col">Payment Status</div>
                    </div>
                    {ordersToShow.map((order, index) => (
                      <div key={index}>
                        <div className="row each-order-container">
                          <div className="d-flex flex-row order-header">
                            <div style={{ fontSize: 11, marginTop: 2 }}>
                              <i
                                className="icofont-brand-natgeo"
                                style={{ color: "black" }}
                              />
                              &nbsp;Order Id: {order.orderId} &nbsp; &nbsp;
                            </div>
                            <div
                              style={{
                                fontSize: 11,
                                color: "gray",
                                marginTop: 2,
                              }}
                            >
                              {order.orderedDate} &nbsp; {order.orderedTime}{" "}
                              &nbsp; &nbsp;
                            </div>

                            <div style={{ fontSize: 11 }}>
                              <i
                                className="icofont-brand-china-unicom"
                                style={{ color: "orange", fontSize: 17 }}
                              />
                              &nbsp;{order.shopName.slice(0, 25)}... &nbsp;
                              &nbsp;
                            </div>
                            <div style={{ fontSize: 11 }}>
                              <i
                                className="icofont-bomb"
                                style={{ color: "blue", fontSize: 17 }}
                              />
                              &nbsp;order status:{" "}
                              <span style={{ color: "#ff8084" }}>
                                {order.orderStatus}
                              </span>{" "}
                              &nbsp; &nbsp;
                            </div>
                          </div>

                          <div
                            className="d-flex flex-row"
                            style={{
                              fontSize: 13,
                              color: "gray",
                              padding: 10,
                              paddingLeft: 30,
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div style={{ marginTop: 5 }}>
                                Tracking No:
                                <span
                                  style={{
                                    color: "gray",
                                    fontSize: 12,
                                    marginLeft: 5,
                                    marginTop: 1,
                                  }}
                                >
                                  {order.trackingNo || "Not given yet"}
                                </span>
                              </div>
                              {order.orderStatus == "Reject" ||
                              order.paymentStatus == "pending" ||
                              order.paymentStatus == "Paid" ||
                              this.getTotalDue(order) <= 0 ? null : (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "white",
                                    background: "orange",
                                    border: "2px solid gainsboro",
                                    borderRadius: 7,
                                    padding: "0px 5px",
                                    display: "inline",
                                    fontSize: 11,
                                    marginTop: 5,
                                    marginLeft: 7,
                                  }}
                                  onClick={() => {
                                    this.startToggleModal([order]);
                                  }}
                                >
                                  Pay now
                                </div>
                              )}
                            </div>
                            {order.shippingChargeCustomer &&
                            order.shippingChargeCustomer !== 0 ? (
                              <Link
                                to={`${
                                  process.env.PUBLIC_URL
                                }/pages/dashboard/buy-and-ship-for/invoice/${
                                  order.orderId
                                }`}
                                target="_blank"
                              >
                                <div
                                  style={{
                                    color: "white",
                                    padding: "2px 7px",
                                    border: "2px solid gainsboro",
                                    borderRadius: 7,
                                    backgroundColor: "#a3422b",
                                    cursor: "pointer",
                                    fontSize: 10,
                                    textAlign: "center",
                                    marginRight: 12,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  <i
                                    className="icofont-dollar"
                                    style={{ fontWeight: "bold" }}
                                  />{" "}
                                  invoice
                                </div>
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row products-box">
                          <div
                            className="col-8"
                            style={{ borderRight: "1px solid gainsboro" }}
                          >
                            {order.items.map((item) =>
                              item.skus.map((sku, index) => (
                                <div
                                  className="row"
                                  style={{
                                    padding: 5,
                                    borderTop: "1px solid gainsboro",

                                    paddingBottom: 25,
                                  }}
                                  key={index}
                                >
                                  <div
                                    className="col-2"
                                    style={{ margin: "auto" }}
                                  >
                                    <div
                                      style={{
                                        minHeight: 80,
                                        minWidth: 80,
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        overflow: "hidden",
                                        backgroundImage: `url(${
                                          sku.image ? sku.image : item.picture
                                        })`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        border: "1px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />
                                  </div>
                                  <div className="col-4">
                                    <div
                                      style={{ color: "#4a4a4a", fontSize: 12 }}
                                    >
                                      {item.name && item.name.slice(0, 50)}...
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 11,
                                      }}
                                    >
                                      Product type: {item.productType}
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 11,
                                      }}
                                    >
                                      Shipping Rate :{" "}
                                      {order.customerRate
                                        ? `${order.customerRate}tk/kg`
                                        : item.shippingRate
                                        ? `${item.shippingRate}tk/kg`
                                        : "Not given yet"}
                                    </div>
                                    {item.propertyNames &&
                                      item.propertyNames.length > 0 && (
                                        <div
                                          style={{
                                            color: "gray",
                                            fontSize: 11,
                                          }}
                                        >
                                          {" "}
                                          {item.propertyNames[0]}: {sku.color}
                                          <br />
                                          {sku.size &&
                                            `${item.propertyNames[1]}: ${
                                              sku.size
                                            }`}
                                        </div>
                                      )}
                                  </div>
                                  <div
                                    className="col"
                                    style={{ color: "rgb(98 98 98)" }}
                                  >
                                    {parseInt(parseFloat(sku.price))}
                                  </div>
                                  <div
                                    className="col"
                                    style={{ color: "rgb(98 98 98)" }}
                                  >
                                    {sku.totalQuantity}
                                  </div>
                                </div>
                              ))
                            )}
                            {order.status !== "Reject" && (
                              <div
                                className="row"
                                style={{
                                  margin: 0,
                                  marginBottom: 40,
                                  marginTop: 65,
                                }}
                              >
                                <div
                                  className={`col ${
                                    order.shipmentStatusScore >= 1
                                      ? "each-progress"
                                      : "each-progress-2"
                                  }`}
                                >
                                  <i className="icofont-paper" />
                                  <span>Pending</span>
                                </div>
                                <div
                                  className={`col ${
                                    order.shipmentStatusScore >= 2
                                      ? "each-progress"
                                      : "each-progress-2"
                                  }`}
                                >
                                  <i className="icofont-tick-boxed" />
                                  <span>Approved</span>
                                </div>
                                <div
                                  className={`col ${
                                    order.shipmentStatusScore >= 3
                                      ? "each-progress"
                                      : "each-progress-2"
                                  }`}
                                >
                                  <i className="icofont-building-alt" />
                                  <span>Abroad</span>
                                  <br />
                                  <span style={{ top: "7px" }}>Warehouse</span>
                                </div>
                                <div
                                  className={`col ${
                                    order.shipmentStatusScore >= 4
                                      ? "each-progress"
                                      : "each-progress-2"
                                  }`}
                                >
                                  <i className="icofont-airplane-alt" />
                                  <span style={{ left: "-15px" }}>Ready </span>
                                  <br />
                                  <span style={{ top: "7px", left: "-15px" }}>
                                    for fly
                                  </span>
                                </div>
                                <div
                                  className={`col ${
                                    order.shipmentStatusScore >= 5
                                      ? "each-progress"
                                      : "each-progress-2"
                                  }`}
                                >
                                  <i className="icofont-shield-alt" />
                                  <span>Bangladesh</span>
                                  <br />
                                  <span style={{ top: "7px" }}> customs</span>
                                </div>
                                <div
                                  className={`col ${
                                    order.shipmentStatusScore >= 6
                                      ? "each-progress"
                                      : "each-progress-2"
                                  }`}
                                >
                                  <i className="icofont-castle" />
                                  <span>Paicart</span>
                                  <br />
                                  <span style={{ top: "7px" }}> warehouse</span>
                                </div>
                                <div
                                  className={`col ${
                                    order.shipmentStatusScore >= 7
                                      ? "last-progress"
                                      : "last-progress-2"
                                  }`}
                                >
                                  <i className="icofont-tick-mark" />
                                  <span>Delivered</span>
                                </div>
                              </div>
                            )}

                            {order.paymentStatus == "Paid" ||
                            order.paymentStatus == "Partially Paid" ? (
                              <>
                                {order.refundStatus ? (
                                  <div
                                    style={{
                                      marginTop: 20,
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "white",
                                        background:
                                          order.refundStatus == "rejected"
                                            ? "red"
                                            : "green",
                                        border: "1px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 10,
                                        marginTop: 5,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Refund {order.refundStatus}!
                                    </div>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: 20 }}>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        color: "white",
                                        background: "#8d8d8d",
                                        border: "1px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 10,
                                        marginTop: 5,
                                        fontWeight: "bold",
                                      }}
                                      data-toggle="modal"
                                      data-target="#refundModal"
                                      onClick={() => {
                                        this.setState({
                                          order: order,
                                          reason: "",
                                          paymentMethod: "",
                                          bankInfo: "",
                                        });
                                      }}
                                    >
                                      Apply for refund
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : null}

                            {order.refund && order.refund !== 0 ? (
                              <div
                                style={{
                                  color: "gray",
                                  fontSize: 10,
                                  marginLeft: 3,
                                }}
                              >
                                Refunded amount:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 10,
                                    color: "#555",
                                  }}
                                >
                                  {order.refund}Tk
                                </span>
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="col-4"
                            style={{
                              paddingTop: 5,
                              borderTop: "1px solid gainsboro",
                              position: "relative",
                              paddingBottom: 20,
                            }}
                          >
                            <div className="row">
                              <div className="col-6">
                                <div
                                  style={{ fontWeight: "bold", fontSize: 16 }}
                                >
                                  {order.actualTotal
                                    ? order.actualTotal
                                    : this.getSingleShopTotal(order)}
                                  Tk
                                </div>

                                {order.shippingChargeCustomer &&
                                order.shippingChargeCustomer !== 0 ? (
                                  <>
                                    <div
                                      style={{ color: "gray", fontSize: 10 }}
                                    >
                                      China local courier charge:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {order.localShipping
                                          ? parseInt(order.localShipping)
                                          : 0}{" "}
                                        Tk
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 10 }}
                                    >
                                      Total weight:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {order.receivedWeight} kg
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 10 }}
                                    >
                                      Customs & Shippping rate:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {order.customerRate} tk/kg
                                      </span>
                                    </div>
                                    <div
                                      style={{ color: "gray", fontSize: 10 }}
                                    >
                                      Total Shipping charge (CN to BD):{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {order.shippingChargeCustomer} Tk
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 16,
                                        marginTop: 20,
                                      }}
                                    >
                                      {order.actualTotal
                                        ? parseInt(order.actualTotal) +
                                          parseInt(
                                            order.shippingChargeCustomer
                                          ) +
                                          parseInt(order.localShipping)
                                        : this.getSingleShopTotal(order) +
                                          parseInt(
                                            order.shippingChargeCustomer
                                          ) +
                                          parseInt(order.localShipping)}
                                      Tk
                                      <div
                                        style={{
                                          fontSize: 10,
                                          color: "gray",
                                          fontWeight: "lighter",
                                        }}
                                      >
                                        including shipping charge
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{ color: "gray", fontSize: 10 }}
                                    >
                                      excluding shipping charge
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 10,
                                        marginTop: 25,
                                      }}
                                    >
                                      China local courier charge:{" "}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          color: "#444",
                                        }}
                                      >
                                        {order.localShipping
                                          ? `${order.localShipping}Tk`
                                          : "Not given yet"}
                                      </span>
                                      <div
                                        style={{ color: "gray", fontSize: 10 }}
                                      >
                                        Customs & Shipping Rate:{" "}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "#444",
                                          }}
                                        >
                                          {order.customerRate
                                            ? `${order.customerRate}tk/kg`
                                            : "Not given yet"}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div style={{ marginTop: 25 }} />
                                {order.otherCost ? (
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 10,
                                    }}
                                  >
                                    Packaging & Other cost:
                                    <span
                                      style={{
                                        color: "#555",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      +{order.otherCost}
                                      Tk
                                    </span>
                                  </div>
                                ) : null}
                                {order.couponAmount &&
                                order.couponAmount !== 0 ? (
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 10,
                                    }}
                                  >
                                    Coupon:
                                    <span
                                      style={{
                                        color: "#555",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      -{order.couponAmount}
                                      Tk
                                    </span>
                                  </div>
                                ) : null}
                                {order.offer && order.offer !== 0 ? (
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 10,
                                    }}
                                  >
                                    Discount (on pay now%):
                                    <span
                                      style={{
                                        color: "#555",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      -
                                      {Math.round(
                                        order.actualTotalAfterCoupon *
                                          (order.offer.split("%")[0] / 100)
                                      )}
                                      Tk
                                    </span>
                                  </div>
                                ) : null}
                                {order.discount ? (
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 10,
                                    }}
                                  >
                                    Discount (on shipping):
                                    <span
                                      style={{
                                        color: "#555",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      -{Math.round(order.discount)}
                                      Tk
                                    </span>
                                  </div>
                                ) : null}
                                <div
                                  style={{
                                    color: "gray",
                                    fontSize: 10,
                                  }}
                                >
                                  Total Paid:
                                  <span
                                    style={{
                                      color: "#555",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    -{this.getTotalPaid(order)}
                                    Tk
                                  </span>
                                </div>

                                <div
                                  style={{
                                    color: "gray",
                                    fontSize: 10,
                                    marginBottom: 35,
                                  }}
                                >
                                  Total Due:
                                  <span
                                    style={{
                                      color: "#ff8084",
                                      fontWeight: "bold",
                                      fontSize: 14,
                                    }}
                                  >
                                    {" "}
                                    {this.getTotalDue(order)}
                                    Tk
                                  </span>
                                </div>
                              </div>
                              <div className="col-6">
                                {order.paymentStatus == "Paid" ||
                                order.paymentStatus == "Partially Paid" ||
                                order.paymentStatus == "purchaseLater" ? (
                                  <>
                                    <div
                                      style={{
                                        color: "white",
                                        background:
                                          order.paymentStatus == "Paid"
                                            ? "darkgreen"
                                            : order.paymentStatus ==
                                              "Partially Paid"
                                            ? "darkorange"
                                            : order.paymentStatus ==
                                              "purchaseLater"
                                            ? "#596fa8"
                                            : "white",
                                        border: "2px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 11,
                                        marginTop: 5,
                                      }}
                                    >
                                      {order.paymentStatus == "Paid"
                                        ? "Paid"
                                        : order.paymentStatus ==
                                          "Partially Paid"
                                        ? "Partially Paid"
                                        : order.paymentStatus == "purchaseLater"
                                        ? "Not Paid"
                                        : "Not Paid"}
                                    </div>
                                  </>
                                ) : (
                                  <div style={{ position: "relative" }}>
                                    <div
                                      className="myDIV"
                                      style={{
                                        color: "white",
                                        background: "#da62da",
                                        border: "2px solid gainsboro",
                                        borderRadius: 7,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 11,
                                        marginTop: 5,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {order.paymentStatus}
                                    </div>
                                    <div
                                      className="hide"
                                      style={{
                                        position: "absolute",
                                        padding: "10px",
                                        borderRadius: 5,
                                        border: "1px solid #18768f",
                                        maxWidth: 120,
                                        minWidth: 120,
                                        zIndex: 100,
                                        background: "#da62da",
                                        fontSize: 11,
                                        color: "white",
                                        right: "0px",
                                        top: "20px",
                                      }}
                                    >
                                      {
                                        "Your payment request is pending.Please wait till your payments get approved by one of our employee."
                                      }
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                color:
                                  order.shippingChargeCustomer &&
                                  order.shippingChargeCustomer !== 0
                                    ? "green"
                                    : "#ff8084",
                                fontSize: 11,
                                position: "absolute",
                                bottom: 0,
                              }}
                            >
                              {order.shippingChargeCustomer &&
                              order.shippingChargeCustomer !== 0
                                ? "***China to Bangladesh customs & shipping charge included."
                                : "***China to Bangladesh customs & shipping charge will be included later."}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div>
              <div className="d-flex flex-row main-navigator-option">
                <div
                  className="buy-ship-for-me-mobile"
                  style={{ borderBottom: "2px solid #f54c3c" }}
                >
                  <div>Buy & Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-request`
                        );
                      }}
                    >
                      My Request Products
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        paddingBottom: 10,
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-orders`
                        );
                      }}
                    >
                      My Orders
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        paddingBottom: 10,
                        color: "#f54c3c",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        paddingBottom: 10,
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 160 }}>
                  <div>Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-request`
                        );
                      }}
                    >
                      My Booking
                    </div>

                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 100 }}>
                  <div>Profile</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/information`
                        );
                      }}
                    >
                      Information
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/security`
                        );
                      }}
                    >
                      Security
                    </div>
                  </div>
                </div>
                <div />
                <div />
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.abroad ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        abroad: true,
                        bangladesh: false,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Abroad to Bangladesh ({ordersAbroad.length})
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.bangladesh ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        abroad: false,
                        bangladesh: true,
                        filterByCategory: "",
                      });
                    }}
                  >
                    Bangladesh to you ({ordersBangladesh.length})
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    justifyContent: "space-between",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div className="col-5">
                    <select
                      title="Filter by category"
                      required
                      name="filterByCategory"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.filterByCategory}
                      style={{ fontSize: 11 }}
                    >
                      <option value="">Filter by category </option>
                      <option value="">All</option>
                      <option value="Paid">Paid</option>
                      <option value="Partially Paid">Partially Paid</option>
                      <option value="purhcaseLater">Not Paid</option>

                      {this.state.abroad ? (
                        <>
                          <option value="Received in Warehouse">
                            Received in Warehouse (abroad)
                          </option>
                          <option value="Ready for fly">Ready for fly</option>
                          <option value="Bangladesh customs">
                            Bangladesh customs
                          </option>
                        </>
                      ) : (
                        <option value="Paicart warehouse">
                          Paicart warehouse
                        </option>
                      )}
                    </select>
                  </div>
                  <div className="col-7">
                    <form className="form-inline subscribe-form">
                      <input
                        type="text"
                        className="form-control extra-height"
                        id="exampleFormControlInput1"
                        name="searchOrder"
                        value={this.state.searchOrder}
                        onChange={this.handleChange}
                        placeholder="Search Order"
                        style={{
                          padding: 8,
                          width: 250,
                          borderColor: "gainsboro",
                          borderRadius: 5,
                        }}
                      />

                      <button
                        type="submit"
                        className="btn btn-solid"
                        style={{ position: "absolute", right: "12px" }}
                      >
                        <i
                          className="icofont-search"
                          style={{ fontWeight: "bold" }}
                        />
                      </button>
                    </form>
                  </div>
                </div>
                {/* when no order is found  */}
                {ordersToShow.length == 0 ? (
                  <div className="main-order-section">
                    <div className="col-sm-12">
                      <div>
                        <div
                          className="col-sm-12 empty-cart-cls text-center"
                          style={{ marginTop: 50 }}
                        >
                          <img
                            src={`${
                              process.env.PUBLIC_URL
                            }/assets/images/empty-search.jpg`}
                            className="img-fluid mb-4"
                            alt=""
                          />
                          <h3 style={{ paddingBottom: 50 }}>
                            <strong>You haven't make any Orders yet!</strong>
                          </h3>
                        </div>
                      </div>
                      <div style={{ paddingBottom: 40, textAlign: "center" }}>
                        <Link to="/">
                          <button
                            className="btn btn-solid"
                            style={{
                              padding: 6,
                              fontSize: 10,
                              borderRadius: 5,
                            }}
                          >
                            Continue Shopping
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ borderRadius: 10, marginBottom: 20 }}>
                    {ordersToShow.map((order, index) => (
                      <div key={index}>
                        <div className="each-order-container-1">
                          <div
                            className="order-header-1"
                            style={{ borderBottom: "1px solid gainsboro" }}
                          >
                            <div style={{ fontSize: 12 }}>
                              Order Id: {order.orderId}{" "}
                              <div style={{ fontSize: 10 }}>
                                Date:{order.orderedDate}
                              </div>
                            </div>

                            <div>
                              {order.orderStatus == "Reject" ||
                              order.paymentStatus == "pending" ||
                              order.paymentStatus == "Paid" ||
                              this.getTotalDue(order) <= 0 ? null : (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "orange",
                                    color: "white",
                                    background: "orange",
                                    border: "1px solid gainsboro",
                                    borderRadius: 7,
                                    padding: "1px 5px",
                                    display: "inline",
                                    fontSize: 11,
                                    marginTop: 5,
                                  }}
                                  onClick={() => {
                                    this.startToggleModal([order]);
                                  }}
                                >
                                  Pay now
                                </div>
                              )}
                              {order.shippingChargeCustomer &&
                              order.shippingChargeCustomer !== 0 ? (
                                <Link
                                  to={`${
                                    process.env.PUBLIC_URL
                                  }/pages/dashboard/buy-and-ship-for/invoice/${
                                    order.orderId
                                  }`}
                                  target="_blank"
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",

                                      color: "white",
                                      background: "#a3422b",
                                      border: "1px solid gainsboro",
                                      borderRadius: 7,
                                      padding: "1px 5px",
                                      display: "inline",
                                      fontSize: 11,
                                      marginTop: 5,
                                      fontWeight: "bold",
                                      marginLeft: 10,
                                    }}
                                  >
                                    {" "}
                                    <i
                                      className="icofont-dollar"
                                      style={{ fontWeight: "bold" }}
                                    />{" "}
                                    invoice
                                  </div>
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="products-box"
                          style={{
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                          }}
                        >
                          <div>
                            {order.items.map((item) =>
                              item.skus.map((sku, index) => (
                                <div
                                  style={{
                                    padding: 5,
                                    paddingBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    borderBottom:
                                      index + 1 == item.skus.length
                                        ? "none"
                                        : "1px solid gainsboro",
                                  }}
                                  key={index}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      marginLeft: 5,
                                    }}
                                  >
                                    <div
                                      style={{
                                        minHeight: 60,
                                        minWidth: 60,
                                        maxHeight: 60,
                                        maxWidth: 60,
                                        overflow: "hidden",
                                        backgroundImage: `url(${
                                          sku.image ? sku.image : item.picture
                                        })`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        border: "1px solid gainsboro",
                                        borderRadius: 5,
                                      }}
                                    />
                                    <div style={{ padding: "0px 10px" }}>
                                      <div
                                        style={{
                                          color: "#222",
                                          fontSize: 12,
                                        }}
                                      >
                                        {item.name && item.name.slice(0, 20)}...
                                      </div>
                                      <div
                                        style={{
                                          color: "gray",
                                          fontSize: 10,
                                        }}
                                      >
                                        Product type: {item.productType}
                                      </div>
                                      <div
                                        style={{
                                          color: "gray",
                                          fontSize: 10,
                                        }}
                                      >
                                        Shipping Rate :{" "}
                                        {order.customerRate
                                          ? `${order.customerRate}tk/kg`
                                          : item.shippingRate
                                          ? `${item.shippingRate}tk/kg`
                                          : "Not given yet"}
                                      </div>
                                      {item.propertyNames &&
                                        item.propertyNames.length > 0 && (
                                          <div
                                            style={{
                                              color: "gray",
                                              fontSize: 10,
                                            }}
                                          >
                                            {item.propertyNames[0]}: {sku.color}
                                            , <br />
                                            {sku.size &&
                                              `${item.propertyNames[1]}: ${
                                                sku.size
                                              }`}
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "rgb(98 98 98)",
                                        fontSize: 12,
                                      }}
                                    >
                                      Tk {parseInt(parseFloat(sku.price))}
                                    </div>
                                    <div
                                      style={{
                                        color: "gray",
                                        fontSize: 12,
                                        marginLeft: 20,
                                      }}
                                    >
                                      X{sku.totalQuantity}
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              marginBottom: 5,
                              marginTop: 10,
                            }}
                          >
                            <div>
                              <div style={{ color: "gray", fontSize: 11 }}>
                                Order Status:{" "}
                                <span
                                  style={{
                                    color: "#ff8084",
                                    fontWeight: "bold",
                                    fontSize: 10,
                                  }}
                                >
                                  {order.orderStatus}
                                </span>{" "}
                                <br />
                              </div>
                              <div style={{ color: "gray", fontSize: 11 }}>
                                Payment Status:{" "}
                                {order.paymentStatus == "Paid" ||
                                order.paymentStatus == "Partially Paid" ||
                                order.paymentStatus == "purchaseLater" ? (
                                  <div
                                    style={{
                                      color: "white",
                                      background:
                                        order.paymentStatus == "Paid"
                                          ? "darkgreen"
                                          : order.paymentStatus ==
                                            "Partially Paid"
                                          ? "darkorange"
                                          : order.paymentStatus ==
                                            "purchaseLater"
                                          ? "#596fa8"
                                          : "white",
                                      border: "1px solid gainsboro",
                                      borderRadius: 5,
                                      padding: "1px 5px",
                                      display: "inline",
                                      fontSize: 7,
                                      marginTop: 5,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {order.paymentStatus == "Paid"
                                      ? "Paid"
                                      : order.paymentStatus == "Partially Paid"
                                      ? "Partially Paid"
                                      : order.paymentStatus == "purchaseLater"
                                      ? "Not Paid"
                                      : "Not Paid"}
                                  </div>
                                ) : (
                                  <span style={{ position: "relative" }}>
                                    <div
                                      style={{
                                        color: "white",
                                        background: "#da62da",
                                        border: "1px solid gainsboro",
                                        borderRadius: 5,
                                        padding: "1px 5px",
                                        display: "inline",
                                        fontSize: 7,
                                        marginTop: 5,
                                        fontWeight: "bold",
                                      }}
                                      className="myDIV"
                                    >
                                      {order.paymentStatus}
                                    </div>
                                    <div
                                      className="hide"
                                      style={{
                                        position: "absolute",
                                        padding: "10px",
                                        borderRadius: 5,
                                        border: "1px solid #18768f",
                                        maxWidth: 120,
                                        minWidth: 120,
                                        zIndex: 100,
                                        background: "#da62da",
                                        fontSize: 10,
                                        color: "white",
                                        right: "0px",
                                        top: "12px",
                                      }}
                                    >
                                      {
                                        "Your payment request is pending.Please wait till your payments get approved by one of our employee."
                                      }
                                    </div>
                                  </span>
                                )}
                              </div>
                              <div style={{ color: "gray", fontSize: 11 }}>
                                Tracking No:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#444",
                                  }}
                                >
                                  {order.trackingNo || "Not given yet"}
                                </span>
                              </div>
                              <div style={{ color: "gray", fontSize: 11 }}>
                                Product Price:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#444",
                                  }}
                                >
                                  {order.actualTotal
                                    ? order.actualTotal
                                    : this.getSingleShopTotal(order)}
                                  Tk
                                </span>
                              </div>
                              <div style={{ color: "gray", fontSize: 11 }}>
                                China local courier charge:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#444",
                                  }}
                                >
                                  {order.localShipping
                                    ? order.localShipping
                                    : 0}{" "}
                                  Tk
                                </span>
                              </div>
                              {order.shippingChargeCustomer &&
                              order.shippingChargeCustomer !== 0 ? (
                                <>
                                  <div style={{ color: "gray", fontSize: 11 }}>
                                    Total weight:{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#444",
                                      }}
                                    >
                                      {order.receivedWeight} kg
                                    </span>
                                  </div>
                                  <div style={{ color: "gray", fontSize: 11 }}>
                                    Customs & Shippping rate:{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#444",
                                      }}
                                    >
                                      {order.customerRate} tk/kg
                                    </span>
                                  </div>
                                  <div style={{ color: "gray", fontSize: 11 }}>
                                    Total Shipping charge (CN to BD):{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#444",
                                      }}
                                    >
                                      {order.shippingChargeCustomer} Tk
                                    </span>
                                  </div>

                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 16,
                                      marginTop: 20,
                                    }}
                                  >
                                    {order.actualTotal
                                      ? parseInt(order.actualTotal) +
                                        parseInt(order.shippingChargeCustomer) +
                                        parseInt(order.localShipping)
                                      : this.getSingleShopTotal(order) +
                                        parseInt(order.shippingChargeCustomer) +
                                        parseInt(order.localShipping)}
                                    Tk
                                    <div
                                      style={{
                                        fontSize: 10,
                                        color: "gray",
                                        fontWeight: "lighter",
                                        marginTop: -3,
                                      }}
                                    >
                                      including shipping charge
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div style={{ color: "gray", fontSize: 11 }}>
                                    Customs & Shippping rate:{" "}
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        color: "#444",
                                      }}
                                    >
                                      {order.customerRate
                                        ? `${order.customerRate}tk/kg`
                                        : "Not given yet"}
                                    </span>
                                  </div>

                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 16,
                                      marginTop: 20,
                                    }}
                                  >
                                    {order.actualTotal
                                      ? order.actualTotal
                                      : this.getSingleShopTotal(order)}
                                    Tk
                                    <div
                                      style={{
                                        fontSize: 10,
                                        color: "gray",
                                        fontWeight: "lighter",
                                        marginTop: -3,
                                      }}
                                    >
                                      excluding shipping charge
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              {order.otherCost ? (
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Packaging & other cost{" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#444",
                                    }}
                                  >
                                    +Tk {order.otherCost}
                                  </span>
                                </div>
                              ) : null}
                              {order.couponAmount &&
                              order.couponAmount !== 0 ? (
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Coupon amout{" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#444",
                                    }}
                                  >
                                    -Tk {order.couponAmount}
                                  </span>
                                </div>
                              ) : null}
                              {order.offer && order.offer !== 0 ? (
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Discount (on pay now%){" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#444",
                                    }}
                                  >
                                    -Tk{" "}
                                    {Math.round(
                                      order.actualTotalAfterCoupon *
                                        (order.offer.split("%")[0] / 100)
                                    )}
                                  </span>
                                </div>
                              ) : null}
                              {order.discount ? (
                                <div style={{ color: "gray", fontSize: 11 }}>
                                  Discount (on shipping){" "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: "#444",
                                    }}
                                  >
                                    -Tk {Math.round(order.discount)}
                                  </span>
                                </div>
                              ) : null}
                              <div style={{ color: "gray", fontSize: 11 }}>
                                Total Paid{" "}
                                <span
                                  style={{ fontWeight: "bold", color: "#444" }}
                                >
                                  -Tk {this.getTotalPaid(order)}
                                </span>
                              </div>

                              <div style={{ color: "gray", fontSize: 11 }}>
                                Total Due{" "}
                                <span
                                  style={{
                                    color: "#ff8084",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Tk {this.getTotalDue(order)}
                                </span>
                              </div>
                              <div style={{ marginTop: 30 }} />
                              {order.paymentStatus == "Paid" ||
                              order.paymentStatus == "Partially Paid" ? (
                                <>
                                  {order.refundStatus ? (
                                    <div>
                                      <div
                                        style={{
                                          color: "white",
                                          background:
                                            order.refundStatus == "rejected"
                                              ? "red"
                                              : "green",
                                          border: "1px solid gainsboro",
                                          borderRadius: 5,
                                          padding: "1px 5px",
                                          display: "inline",
                                          fontSize: 7,

                                          fontWeight: "bold",
                                        }}
                                      >
                                        Refund {order.refundStatus}!
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        color: "white",
                                        background: "#8d8d8d",
                                        border: "1px solid gainsboro",
                                        borderRadius: 5,
                                        padding: "2px 5px",
                                        display: "inline",
                                        fontSize: 7,

                                        fontWeight: "bold",
                                      }}
                                      data-toggle="modal"
                                      data-target="#refundModal"
                                      onClick={() => {
                                        this.setState({
                                          order: order,
                                          reason: "",
                                          paymentMethod: "",
                                          bankInfo: "",
                                        });
                                      }}
                                    >
                                      Apply for refund
                                    </div>
                                  )}
                                </>
                              ) : null}
                              <div>
                                {order.refund && order.refund != 0 ? (
                                  <div
                                    style={{
                                      color: "gray",
                                      fontSize: 11,
                                      marginLeft: 3,
                                    }}
                                  >
                                    Refunded amount: <br />
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 10,
                                        color: "#555",
                                      }}
                                    >
                                      {order.refund}Tk
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {order.status !== "Reject" && (
                            <div
                              className="row"
                              style={{
                                margin: 0,
                                marginBottom: 20,
                                marginTop: 30,
                              }}
                            >
                              <div
                                className={`col ${
                                  order.shipmentStatusScore >= 1
                                    ? "each-progress-3"
                                    : "each-progress-4"
                                }`}
                              >
                                <i className="icofont-paper" />
                                <span>Pending</span>
                              </div>
                              <div
                                className={`col ${
                                  order.shipmentStatusScore >= 2
                                    ? "each-progress-3"
                                    : "each-progress-4"
                                }`}
                              >
                                <i className="icofont-tick-boxed" />
                                <span>Approved</span>
                              </div>
                              <div
                                className={`col ${
                                  order.shipmentStatusScore >= 3
                                    ? "each-progress-3"
                                    : "each-progress-4"
                                }`}
                              >
                                <i className="icofont-building-alt" />
                                <span>Abroad</span>
                                <br />
                                <span style={{ top: "-10px" }}>Warehouse</span>
                              </div>
                              <div
                                className={`col ${
                                  order.shipmentStatusScore >= 4
                                    ? "each-progress-3"
                                    : "each-progress-4"
                                }`}
                              >
                                <i className="icofont-airplane-alt" />
                                <span style={{ left: "-15px" }}>Ready </span>
                                <br />
                                <span style={{ top: "-10px", left: "-15px" }}>
                                  fly
                                </span>
                              </div>
                              <div
                                className={`col ${
                                  order.shipmentStatusScore >= 5
                                    ? "each-progress-3"
                                    : "each-progress-4"
                                }`}
                              >
                                <i className="icofont-shield-alt" />
                                <span>Bangladesh</span>
                                <br />
                                <span style={{ top: "-10px" }}> customs</span>
                              </div>
                              <div
                                className={`col ${
                                  order.shipmentStatusScore >= 6
                                    ? "each-progress-3"
                                    : "each-progress-4"
                                }`}
                              >
                                <i className="icofont-castle" />
                                <span>Paicart</span>
                                <br />
                                <span style={{ top: "-10px" }}> warehouse</span>
                              </div>
                              <div
                                className={`col ${
                                  order.shipmentStatusScore >= 7
                                    ? "last-progress-3"
                                    : "last-progress-4"
                                }`}
                              >
                                <i className="icofont-tick-mark" />
                                <span>Delivered</span>
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              color:
                                order.shippingChargeCustomer &&
                                order.shippingChargeCustomer !== 0
                                  ? "green"
                                  : "#ff8084",
                              fontSize: 8,
                              marginTop: 10,
                            }}
                          >
                            {order.shippingChargeCustomer &&
                            order.shippingChargeCustomer !== 0
                              ? "***China to Bangladesh customs & shipping charge included."
                              : "***China to Bangladesh customs & shipping charge will be included later."}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="refundModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: 1000000000 }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{ borderBottom: "1px solid black" }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Apply for refund
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="refund-info-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      marginBottom: 25,
                    }}
                  >
                    Order Id:{this.state.order && this.state.order.orderId}
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Select the reason
                    </label>
                    <select
                      title=""
                      name="reason"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.reason}
                      required
                    >
                      <option value="">Select reason for refund </option>
                      <option value="Out of stock">Out of stock</option>
                      <option value="Forbidden product">
                        Forbidden product
                      </option>
                      <option value="Fake product/price">
                        Fake product/price
                      </option>
                      <option value="I don’t want to buy">
                        I don’t want to buy
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Select method
                    </label>
                    <select
                      title=""
                      name="paymentMethod"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.paymentMethod}
                      required
                    >
                      <option value="">Select payment receive method </option>
                      <option value="Bkash">Bkash</option>
                      <option value="Bank">Bank</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Bkash Number/Bank Information
                    </label>
                    <textarea
                      name="bankInfo"
                      type="text"
                      value={this.state.bankInfo}
                      onChange={this.handleChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Bkash Number/Bank Information"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                        height: 150,
                        textAlign: "left",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.applyForRefund();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="successModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: 1000000000 }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="company-info-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="container" style={{ padding: 10 }}>
                    <div
                      className="row"
                      style={{ padding: 10, justifyContent: "center" }}
                    >
                      <img style={{ height: 60, width: 80 }} src={Success} />
                    </div>
                    <div
                      className="row"
                      style={{
                        padding: 10,
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      Your refund reqeust is submitted!
                    </div>
                    <div style={{ color: "gray", textAlign: "center" }}>
                      After verifying your request You will get your refund
                      amount after 7 working days.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="success-info-open"
          data-toggle="modal"
          data-target="#successModal"
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  bookingsArray: state.bookingRequests.bookingsArrayOfSingleUser,
  orders: state.orders.ordersApi,
  currency: state.user.currency,
});

export default connect(
  mapStateToProps,
  { getAllOrdersApiRedux, getOrderTrackingResultRedux, uploadRefundApplyRedux }
)(MyForwardingParcels);
