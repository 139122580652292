import React, { Component } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../myOrders.css";
import "./information.css";
import CompanyInfo from "./companyInfo.png";
import PersonalInfo from "./personalInfo.png";
import { updateUser } from "../../../../firebase/firebase.utils";
import themeSettings from "../../../common/theme-settings";
const address = require("@bangladeshi/bangladesh-address");
class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminNav: false,
      style: { left: "-350px" },
      addressBook: false,
      personalInformation: true,
      companyInformation: false,
      displayName: "",
      gender: "",
      mobileNo: "",
      company: {
        name: "",
        businessType: "",
        address: "",
        mobileNo: "",
      },
      addName: "",
      addMobileNo: "",
      division: "",
      district: "",
      thana: "",
      addAddress: "",
      defaultBilling: false,
      defaultShipping: false,
    };
  }

  componentDidMount = () => {
    console.log(this.props.history.location);
    console.log(address.allDivision());

    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.from == "place-order"
    ) {
      this.setState({
        addressBook: true,
        personalInformation: false,
        companyInformation: false,
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { currentUser } = nextProps;
    if (currentUser.id && this.props.currentUser.id !== currentUser.id) {
      this.setState({
        displayName: currentUser.displayName,
        gender: currentUser.gender,
        mobileNo: currentUser.mobileNo,
        company: currentUser.company ? currentUser.company : this.state.company,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  updateUserInfo = async () => {
    const { currentUser } = this.props;
    await updateUser({
      ...currentUser,
      displayName: this.state.displayName,
      gender: this.state.gender,
      mobileNo: this.state.mobileNo,
    });
    document.getElementById("personal-info-close").click();
  };

  updateCompanyInfo = async () => {
    const { currentUser } = this.props;
    await updateUser({
      ...currentUser,
      company: this.state.company,
    });
    document.getElementById("company-info-close").click();
  };

  updateAddressBook = async () => {
    const { currentUser } = this.props;
    if (
      !this.state.addMobileNo ||
      !this.state.addName ||
      !this.state.addAddress ||
      !this.state.district ||
      !this.state.thana ||
      !this.state.division
    ) {
      alert("Please fill all missing information.");
      return;
    }
    let newAddress = {
      id: parseInt(Math.random() * 10000 - 1),
      name: this.state.addName,
      address: this.state.addAddress,
      mobileNo: this.state.addMobileNo,
      district: this.state.district,
      thana: this.state.thana,
      division: this.state.division,
      defaultShipping: this.state.defaultShipping,
      defaultBilling: this.state.defaultBilling,
    };
    let newAddressBook =
      currentUser.addressBook && currentUser.addressBook.length > 0
        ? currentUser.addressBook
        : [];
    if (
      currentUser.addressBook &&
      currentUser.addressBook.length > 0 &&
      (this.state.defaultShipping || this.state.defaultBilling)
    ) {
      if (this.state.defaultShipping) {
        newAddressBook = newAddressBook.map((address) => {
          if (address.defaultShipping) {
            return { ...address, defaultShipping: false };
          } else {
            return address;
          }
        });
      }
      if (this.state.defaultBilling) {
        newAddressBook = newAddressBook.map((address) => {
          if (address.defaultBilling) {
            return { ...address, defaultBilling: false };
          } else {
            return address;
          }
        });
      }
    }
    await updateUser({
      ...currentUser,
      addressBook: [...newAddressBook, newAddress],
    });
    this.setState({
      addName: "",
      addMobileNo: "",
      district: "",
      thana: "",
      division: "",
      addAddress: "",
      defaultBilling: false,
      defaultShipping: false,
    });
    document.getElementById("address-info-close").click();
    if (
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.from == "place-order"
    ) {
      this.props.history.goBack();
    }
  };

  deleteAddress = async (address) => {
    const { currentUser } = this.props;
    await updateUser({
      ...currentUser,
      addressBook: currentUser.addressBook.filter(
        (address1) => address.id !== address1.id
      ),
    });
  };

  render() {
    const { currentUser } = this.props;
    console.log(currentUser);
    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section
          className="section-b-space main-container-cart"
          style={{ backgroundColor: "#f8f8f8", marginBottom: 20 }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div className="row" style={{ padding: 10 }}>
              <div className="col-3 left-list-column">
                <div className="left-list-container">
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Buy & Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-request`
                          );
                        }}
                      >
                        My Request Products
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-orders`
                          );
                        }}
                      >
                        My Orders
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Ship For me
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-request`
                          );
                        }}
                      >
                        My Booking
                      </p>

                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                          );
                        }}
                      >
                        My Forwarding Parcel
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/ship-for-me/my-delivered`
                          );
                        }}
                      >
                        Delivered
                      </p>
                    </div>
                    <div className="vertical-line" />
                  </div>
                  <div className="option-container">
                    <h5
                      style={{
                        fontWeight: "bold",
                        letterSpacing: 1,
                        fontSize: 16,
                      }}
                    >
                      Profile
                    </h5>
                    <div className="only-option-container">
                      <p
                        className="each-option"
                        style={{ color: "#f54c3c" }}
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/information`
                          );
                        }}
                      >
                        Information
                      </p>
                      <p
                        className="each-option"
                        onClick={() => {
                          this.props.history.push(
                            `${
                              process.env.PUBLIC_URL
                            }/pages/dashboard/user/security`
                          );
                        }}
                      >
                        Security
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.addressBook ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        addressBook: true,
                        personalInformation: false,
                        companyInformation: false,
                      });
                    }}
                  >
                    Address Information
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.personalInformation ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        addressBook: false,
                        personalInformation: true,
                        companyInformation: false,
                      });
                    }}
                  >
                    Personal Information
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.companyInformation ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        addressBook: false,
                        personalInformation: false,
                        companyInformation: true,
                      });
                    }}
                  >
                    Company Information{" "}
                  </div>
                </div>
                {this.state.companyInformation && (
                  <div
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <div className="each-order-container-1">
                      <div
                        className="order-header-1"
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: 12,
                        }}
                      >
                        <div style={{ fontSize: 18, fontWeight: "bold" }}>
                          Company/Store Information
                        </div>
                      </div>
                    </div>
                    <div
                      className="products-box"
                      style={{
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          paddingTop: 15,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Name:{" "}
                            {currentUser.company && currentUser.company.name}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Business Type:{" "}
                            {currentUser.company &&
                              currentUser.company.businessType}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Address:{" "}
                            {currentUser.company && currentUser.company.address}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Mobile:{" "}
                            {currentUser.company &&
                              currentUser.company.mobileNo}
                          </div>
                          <div
                            style={{
                              padding: 12,
                              color: "#5d6154",
                            }}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <i
                                className="icofont-edit"
                                style={{ color: "darkorange" }}
                              />{" "}
                              &nbsp;Edit
                            </div>
                          </div>
                        </div>
                        <div>
                          <img
                            className="img-fluid"
                            src={CompanyInfo}
                            style={{
                              width: 300,
                              height: 140,
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.personalInformation && (
                  <div
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <div className="each-order-container-1">
                      <div
                        className="order-header-1"
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: 12,
                        }}
                      >
                        <div style={{ fontSize: 18, fontWeight: "bold" }}>
                          Personal Information
                        </div>
                      </div>
                    </div>
                    <div
                      className="products-box"
                      style={{
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          paddingTop: 15,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              padding: 4,
                              color: "black",
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                          >
                            {currentUser.displayName}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Email: {currentUser.email}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Gender: {currentUser.gender}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Mobile: {currentUser.mobileNo}
                          </div>
                          <div
                            style={{
                              padding: 12,
                              color: "#5d6154",
                            }}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              data-toggle="modal"
                              data-target="#personalInfoModal"
                            >
                              <i
                                className="icofont-edit"
                                style={{ color: "darkorange" }}
                              />{" "}
                              &nbsp;Edit
                            </div>
                          </div>
                        </div>
                        <div>
                          <img
                            className="img-fluid"
                            src={PersonalInfo}
                            style={{
                              width: 200,
                              height: 150,
                              overflow: "hidden",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.addressBook && (
                  <div
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <div className="each-order-container-1">
                      <div
                        className="order-header-1"
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: 12,
                        }}
                      >
                        <div style={{ fontSize: 18, fontWeight: "bold" }}>
                          Address Book
                        </div>
                      </div>
                    </div>
                    <div
                      className="products-box"
                      style={{
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    >
                      {currentUser.addressBook &&
                      currentUser.addressBook.length > 0
                        ? currentUser.addressBook.map((address, index) => (
                            <div
                              style={{
                                margin: 5,
                                paddingTop: 15,
                                paddingLeft: 20,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                background: address.defaultShipping
                                  ? "#e6faff"
                                  : "white",
                                cursor: "pointer",
                                border: address.defaultShipping
                                  ? "1px solid #18768f"
                                  : "none",
                                borderRadius: 20,
                              }}
                              key={index}
                            >
                              <div
                                onClick={async () => {
                                  let newAddressBook = currentUser.addressBook.map(
                                    (addr) => {
                                      if (addr.id == address.id) {
                                        return {
                                          ...addr,
                                          defaultShipping: true,
                                          defaultBilling: true,
                                        };
                                      } else {
                                        return {
                                          ...addr,
                                          defaultShipping: false,
                                          defaultBilling: false,
                                        };
                                      }
                                    }
                                  );
                                  await updateUser({
                                    ...currentUser,
                                    addressBook: [...newAddressBook],
                                  });
                                  if (
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.from ==
                                      "place-order"
                                  ) {
                                    this.props.history.goBack();
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    padding: 4,
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: 18,
                                  }}
                                >
                                  {address.name} &nbsp;
                                  {address.defaultShipping && (
                                    <i
                                      className="icofont-check-circled"
                                      style={{
                                        color: "#18768f",
                                        fontWeight: "bold",
                                      }}
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    padding: 4,
                                    color: "#5d6154",
                                  }}
                                >
                                  {address.address}
                                </div>
                                <div
                                  style={{
                                    padding: 4,
                                    color: "#5d6154",
                                  }}
                                >
                                  {address.thana}, {address.district},{" "}
                                  {address.division}
                                </div>
                                <div
                                  style={{
                                    padding: 4,
                                    color: "#5d6154",
                                  }}
                                >
                                  {address.mobileNo}
                                </div>
                                <div>
                                  {address.defaultShipping && (
                                    <div
                                      style={{
                                        padding: 2,
                                        backgroundColor: "darkorange",
                                        borderRadius: 3,
                                        color: "white",
                                        fontSize: 11,
                                        maxWidth: 100,
                                        textAlign: "center",
                                        marginTop: 13,
                                      }}
                                    >
                                      default shipping
                                    </div>
                                  )}
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                  {address.defaultBilling && (
                                    <div
                                      style={{
                                        padding: 2,
                                        backgroundColor: "darkorange",
                                        borderRadius: 3,
                                        color: "white",
                                        fontSize: 11,
                                        maxWidth: 100,
                                        marginTop: 7,
                                        textAlign: "center",
                                      }}
                                    >
                                      default billing
                                    </div>
                                  )}
                                </div>
                              </div>
                              <span
                                onClick={() => this.deleteAddress(address)}
                                style={{ cursor: "pointer", marginRight: 20 }}
                                aria-hidden="true"
                              >
                                &times;
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                    <button
                      className="btn"
                      style={{
                        cursor: "pointer",
                        padding: 7,
                        backgroundColor: "darkorange",
                        borderRadius: 5,
                        color: "white",
                        marginTop: 15,
                      }}
                      data-toggle="modal"
                      data-target="#addressInfoModal"
                    >
                      + Add New Address
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* mobile view  */}
        <section
          className="section-b-space mobile-view"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="container" style={{ padding: 10 }}>
            <div>
              <div className="d-flex flex-row main-navigator-option">
                <div className="buy-ship-for-me-mobile">
                  <div>Buy & Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-request`
                        );
                      }}
                    >
                      My Request Products
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-orders`
                        );
                      }}
                    >
                      My Orders
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/buy-and-ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: 160 }}>
                  <div>Ship For me</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "gray",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-request`
                        );
                      }}
                    >
                      My Booking
                    </div>

                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-forwarding-parcel`
                        );
                      }}
                    >
                      My Forwarding Parcel
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/ship-for-me/my-delivered`
                        );
                      }}
                    >
                      Delivered
                    </div>
                  </div>
                </div>
                <div
                  style={{ minWidth: 100, borderBottom: "2px solid #f54c3c" }}
                >
                  <div>Profile</div>
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{
                        fontSize: 10,
                        padding: "10px 0px",
                        color: "#f54c3c",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/information`
                        );
                      }}
                    >
                      Information
                    </div>
                    <div
                      style={{ fontSize: 10, paddingBottom: 10, color: "gray" }}
                      onClick={() => {
                        this.props.history.push(
                          `${
                            process.env.PUBLIC_URL
                          }/pages/dashboard/user/security`
                        );
                      }}
                    >
                      Security
                    </div>
                  </div>
                </div>
                <div />
                <div />
              </div>
            </div>
            <div className="row" style={{ padding: 10 }}>
              <div className="col right-detail-column">
                <div className="row top-row">
                  <div
                    className={`col select-option ${
                      this.state.addressBook ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        addressBook: true,
                        personalInformation: false,
                        companyInformation: false,
                      });
                    }}
                  >
                    Address Information
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.personalInformation ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        addressBook: false,
                        personalInformation: true,
                        companyInformation: false,
                      });
                    }}
                  >
                    Personal Information
                  </div>
                  <div
                    className={`col select-option ${
                      this.state.companyInformation ? "selected-option" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        addressBook: false,
                        personalInformation: false,
                        companyInformation: true,
                      });
                    }}
                  >
                    Company Information
                  </div>
                </div>
                {this.state.companyInformation && (
                  <div
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <div className="each-order-container-1">
                      <div
                        className="order-header-1"
                        style={{ borderBottom: "1px solid gainsboro" }}
                      >
                        <div style={{ fontSize: 15, fontWeight: "bold" }}>
                          Company/Store Information
                        </div>
                      </div>
                    </div>
                    <div
                      className="products-box"
                      style={{
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          padding: 4,
                          color: "#5d6154",
                          fontWeight: "bold",
                        }}
                      >
                        Name: {currentUser.company && currentUser.company.name}
                      </div>
                      <div
                        style={{
                          padding: 4,
                          color: "#5d6154",
                          fontWeight: "bold",
                        }}
                      >
                        Business Type:{" "}
                        {currentUser.company &&
                          currentUser.company.businessType}
                      </div>
                      <div
                        style={{
                          padding: 4,
                          color: "#5d6154",
                          fontWeight: "bold",
                        }}
                      >
                        Address:{" "}
                        {currentUser.company && currentUser.company.address}
                      </div>
                      <div
                        style={{
                          padding: 4,
                          color: "#5d6154",
                          fontWeight: "bold",
                        }}
                      >
                        Mobile:{" "}
                        {currentUser.company && currentUser.company.mobileNo}
                      </div>
                      <div
                        style={{
                          padding: 12,
                          color: "#5d6154",
                        }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i
                            className="icofont-edit"
                            style={{ color: "darkorange" }}
                          />{" "}
                          &nbsp;Edit
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.personalInformation && (
                  <div
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <div className="each-order-container-1">
                      <div
                        className="order-header-1"
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: 12,
                        }}
                      >
                        <div style={{ fontSize: 18, fontWeight: "bold" }}>
                          Personal Information
                        </div>
                      </div>
                    </div>
                    <div
                      className="products-box"
                      style={{
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        paddingLeft: 20,
                      }}
                    >
                      <div
                        style={{
                          paddingTop: 15,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              padding: 4,
                              color: "black",
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                          >
                            {currentUser.displayName}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Email: {currentUser.email}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Gender: {currentUser.gender}
                          </div>
                          <div
                            style={{
                              padding: 4,
                              color: "#5d6154",
                              fontWeight: "bold",
                            }}
                          >
                            Mobile: {currentUser.mobileNo}
                          </div>
                          <div
                            style={{
                              padding: 12,
                              color: "#5d6154",
                            }}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              data-toggle="modal"
                              data-target="#personalInfoModal"
                            >
                              <i
                                className="icofont-edit"
                                style={{ color: "darkorange" }}
                              />{" "}
                              &nbsp;Edit
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.addressBook && (
                  <div
                    style={{
                      borderRadius: 10,
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <div className="each-order-container-1">
                      <div
                        className="order-header-1"
                        style={{
                          borderBottom: "1px solid gainsboro",
                          paddingBottom: 12,
                        }}
                      >
                        <div style={{ fontSize: 18, fontWeight: "bold" }}>
                          Address Book
                        </div>
                      </div>
                    </div>
                    <div
                      className="products-box"
                      style={{
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    >
                      {currentUser.addressBook &&
                      currentUser.addressBook.length > 0
                        ? currentUser.addressBook.map((address, index) => (
                            <div
                              style={{
                                paddingTop: 15,
                                paddingLeft: 20,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                background: address.defaultShipping
                                  ? "#e6faff"
                                  : "white",
                                cursor: "pointer",
                                border: address.defaultShipping
                                  ? "1px solid #18768f"
                                  : "none",
                                borderRadius: 20,
                              }}
                              key={index}
                            >
                              <div
                                onClick={async () => {
                                  let newAddressBook = currentUser.addressBook.map(
                                    (addr) => {
                                      if (addr.id == address.id) {
                                        return {
                                          ...addr,
                                          defaultShipping: true,
                                          defaultBilling: true,
                                        };
                                      } else {
                                        return {
                                          ...addr,
                                          defaultShipping: false,
                                          defaultBilling: false,
                                        };
                                      }
                                    }
                                  );
                                  await updateUser({
                                    ...currentUser,
                                    addressBook: [...newAddressBook],
                                  });
                                  if (
                                    this.props.history.location &&
                                    this.props.history.location.state &&
                                    this.props.history.location.state.from ==
                                      "place-order"
                                  ) {
                                    this.props.history.goBack();
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    padding: 4,
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: 18,
                                  }}
                                >
                                  {address.name}
                                  {address.defaultShipping && (
                                    <i
                                      className="icofont-check-circled"
                                      style={{
                                        color: "#18768f",
                                        fontWeight: "bold",
                                      }}
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    padding: 4,
                                    color: "#5d6154",
                                  }}
                                >
                                  {address.address}
                                </div>
                                <div
                                  style={{
                                    padding: 4,
                                    color: "#5d6154",
                                  }}
                                >
                                  {address.thana}, {address.district},{" "}
                                  {address.division}
                                </div>
                                <div
                                  style={{
                                    padding: 4,
                                    color: "#5d6154",
                                  }}
                                >
                                  {address.mobileNo}
                                </div>
                                <div>
                                  {address.defaultShipping && (
                                    <div
                                      style={{
                                        padding: 2,
                                        backgroundColor: "darkorange",
                                        borderRadius: 3,
                                        color: "white",
                                        fontSize: 11,
                                        maxWidth: 100,
                                        textAlign: "center",
                                        marginTop: 13,
                                      }}
                                    >
                                      default shipping
                                    </div>
                                  )}
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                  {address.defaultBilling && (
                                    <div
                                      style={{
                                        padding: 2,
                                        backgroundColor: "darkorange",
                                        borderRadius: 3,
                                        color: "white",
                                        fontSize: 11,
                                        maxWidth: 100,
                                        marginTop: 7,
                                        textAlign: "center",
                                      }}
                                    >
                                      default billing
                                    </div>
                                  )}
                                </div>
                              </div>
                              <span
                                style={{ cursor: "pointer", marginRight: 20 }}
                                aria-hidden="true"
                                onClick={() => this.deleteAddress(address)}
                              >
                                &times;
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="btn"
                        style={{
                          cursor: "pointer",
                          padding: 7,
                          backgroundColor: "darkorange",
                          borderRadius: 5,
                          color: "white",
                          marginTop: 15,
                          alignSelf: "center",
                        }}
                        data-toggle="modal"
                        data-target="#addressInfoModal"
                      >
                        + Add New Address
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Update Company/Store Information
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="company-info-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      value={this.state.company.name}
                      onChange={(e) => {
                        this.setState({
                          company: {
                            ...this.state.company,
                            name: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Company Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Business Type
                    </label>
                    <input
                      type="text"
                      value={this.state.company.businessType}
                      onChange={(e) => {
                        this.setState({
                          company: {
                            ...this.state.company,
                            businessType: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your business type"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      value={this.state.company.address}
                      onChange={(e) => {
                        this.setState({
                          company: {
                            ...this.state.company,
                            address: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Company/Store Address"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      value={this.state.company.mobileNo}
                      onChange={(e) => {
                        this.setState({
                          company: {
                            ...this.state.company,
                            mobileNo: e.target.value,
                          },
                        });
                      }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Mobile Number"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => this.updateCompanyInfo()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="personalInfoModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Update Personal Information
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      name="displayName"
                      onChange={this.handleChange}
                      value={this.state.displayName}
                      placeholder="Enter Your Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Gender
                    </label>
                    <select
                      title=""
                      name="gender"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.gender}
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobileNo"
                      onChange={this.handleChange}
                      value={this.state.mobileNo}
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Mobile Number"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  className="btn "
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => this.updateUserInfo()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="addressInfoModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Add/Update Address Information
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="address-info-close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="addName"
                      onChange={this.handleChange}
                      value={this.state.addName}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      name="addMobileNo"
                      value={this.state.addMobileNo}
                      onChange={this.handleChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Your Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Division
                    </label>
                    <select
                      title=""
                      name="division"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.division}
                      required
                    >
                      <option value="">Select Division</option>
                      {address.allDivision().map((division) => {
                        return <option value={division}>{division}</option>;
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      District
                    </label>
                    <select
                      title=""
                      name="district"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.district}
                      required
                    >
                      {this.state.division ? (
                        <>
                          <option value="">Select District</option>
                          {address
                            .districtsOf(
                              address.DivisonName[this.state.division]
                            )
                            .map((district) => {
                              return (
                                <option value={district}>{district}</option>
                              );
                            })}
                        </>
                      ) : (
                        <option value="">Select Division first</option>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Thana
                    </label>
                    <select
                      title=""
                      name="thana"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.thana}
                      required
                    >
                      {this.state.district ? (
                        <>
                          <option value="">Select Thana</option>
                          {address
                            .upazilasOf(this.state.district)
                            .map((thana) => {
                              return (
                                <option value={thana.upazila}>
                                  {thana.upazila}
                                </option>
                              );
                            })}
                        </>
                      ) : (
                        <option value="">Select District first</option>
                      )}
                    </select>
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                      }}
                    >
                      Street Address
                    </label>
                    <input
                      type="text"
                      name="addAddress"
                      value={this.state.addAddress}
                      onChange={this.handleChange}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="ex: floor-6A, 431/12, NORTH JATRABARI"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div
                    className="form-check"
                    style={{ marginBottom: 10, marginTop: 20 }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      checked={this.state.defaultShipping}
                      onChange={() => {
                        this.setState({
                          defaultShipping: !this.state.defaultShipping,
                        });
                      }}
                      style={{ marginTop: 8 }}
                    />
                    <label class="form-check-label" style={{ marginTop: 3 }}>
                      set as my default shipping address
                    </label>
                  </div>
                  <div className="form-check" style={{ marginBottom: 30 }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      style={{ marginTop: 8 }}
                      checked={this.state.defaultBilling}
                      onChange={() => {
                        this.setState({
                          defaultBilling: !this.state.defaultBilling,
                        });
                      }}
                    />
                    <label class="form-check-label" style={{ marginTop: 3 }}>
                      set as my default billing address
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => this.updateAddressBook()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(Information);
